// React specific
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

// Services and state
import {
  useJsonApi,
  useBuild,
} from '../../../../services/GlobalStore/GlobalStore';

// Components
import AccountContainer from '../../../../components/AccountContainer';
import ClaimDetailsSubheader from '../ClaimDetailsSubheader';
import claimDetailsSideMenuItems from '../claimDetailsSideMenuItems';
import Loading from '../../../../components/Loading';
import Button from '../../../../components/Button';
import StepTitle from '../../../../components/StepTitle';

// Helpers
import { getIdFromNumber } from '../../../ClaimPage/Claim';
import getTranslatedClaimFlightStatus from '../../../../services/resources/claimGetters/filterFlightStatus';
import getClaimFlightsSchedule from '../../../../services/resources/claimGetters/filterFlightRoute';

// Translations
import claimPassengerListMessages from './claimPassengerListMessages';

// Models
import { PassengerModel } from '../../../../models/PassengerModel';
import ClaimPassengerListItem from './ClaimPassengerListItem';
/**
 * Component show overview of passenger list
 */
const ClaimPassengerList = (props: {}): JSX.Element => {
  const { formatMessage } = useIntl();
  const { claimNumber } = props;
  const claimId = getIdFromNumber(claimNumber);
  // Get it from global store if not hard reloaded
  const claim = useBuild('claims', claimId);

  const [showNewPassenger, setShowNewPassenger] = useState(false);
  const [passengers, setPassengers] = useState<PassengerModel[]>();

  // Get passengers for specific claim
  const {
    get: loadPassengers,
    data: dbPassengers,
    isLoaded: passengersLoaded,
  } = useJsonApi(
    `claims/${claimId}/passengers`,
    {
      credentials: 'include',
      cachePolicy: 'no-cache',
      queryParams: {
        include: [
          'person.legalGuardians.country',
          'person.legalGuardians.countryOfBirth',
          'person.country',
          'person.countryOfBirth',
        ].join(),
      },
    },
    []
  );

  // Get claim informations
  const { isLoaded: claimLoaded } = useJsonApi(
    `claims/${claimId}`,
    {
      credentials: 'include',
      cachePolicy: 'no-cache',
      queryParams: {
        include: [
          'customer',
          'currentState',
          'placeOfJurisdiction',
          'customerFlights.airline',
          'customerFlights.departureAirport',
          'customerFlights.departureAirport.city',
          'customerFlights.arrivalAirport',
          'customerFlights.arrivalAirport.city',
          'flights.airline',
          'flights.departureAirport',
          'flights.departureAirport.city',
          'flights.arrivalAirport',
          'flights.arrivalAirport.city',
        ].join(),
      },
    },
    []
  );

  useEffect(() => {
    if (passengersLoaded) {
      setPassengers(dbPassengers);
    }
  }, [passengersLoaded]);

  function showNewPassengerForm() {
    setShowNewPassenger(true);
  }

  function hideNewPassengerForm() {
    setShowNewPassenger(false);
  }

  return claimLoaded && passengers ? (
    <>
      <ClaimDetailsSubheader
        claimNumber={claimNumber}
        flightStatus={formatMessage(getTranslatedClaimFlightStatus(claim))}
        customerFlights={getClaimFlightsSchedule(claim)}
      />
      <AccountContainer menuItems={claimDetailsSideMenuItems(claimNumber)}>
        <StepTitle className={'mb-20'}>
          {formatMessage(claimPassengerListMessages.claimPassengerTitle)}
        </StepTitle>
        {passengers?.map(passenger => (
          <ClaimPassengerListItem
            key={passenger?.id}
            passengerId={passenger.id}
            claimId={claimId}
            reloadPassengers={loadPassengers}
            readonly={claim.approved}
            onlyOnePassenger={passengers?.length <= 1}
          />
        ))}
        {showNewPassenger ? (
          <ClaimPassengerListItem
            claimId={claimId}
            reloadPassengers={loadPassengers}
            handleRemoveNewPassenger={hideNewPassengerForm}
            readonly={claim.approved}
            onlyOnePassenger={passengers?.length <= 1}
          />
        ) : null}
        {claim?.approved ? null : (
          <Button
            type={'button'}
            className={'my-30'}
            theme="secondary"
            size="small"
            showIcon={false}
            onClick={showNewPassengerForm}
          >
            {formatMessage(
              claimPassengerListMessages.claimPassengerAddNewPassengerButton
            )}
          </Button>
        )}
      </AccountContainer>
    </>
  ) : (
    <div className={'flex justify-center'}>
      <Loading />
    </div>
  );
};
export default ClaimPassengerList;
