import React from 'react';
import claudiaLogo from '../../../../assets/images/account-page/Claudia.png';
import messages from '../newMessages';
import { useIntl } from 'react-intl';
import { isMobile } from 'react-device-detect';

const HelpTopHeader = ({ searchedText }: { searchedText: string }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <div className="w-full">
        <img
          src={claudiaLogo}
          alt=""
          draggable="false"
          style={{
            objectFit: 'cover',
            borderRadius: '50%',
            height: searchedText ? '65px' : '85px',
            width: searchedText ? '65px' : '85px',
            float: searchedText ? 'left' : 'none',
            margin: searchedText ? 'none' : '0 auto',
            border: '3.58px solid #56AA8B',
            pointerEvents: 'none',
          }}
        />
        {!searchedText?.length ? (
          <div className="flex flex-col items-center mt-10">
            <span style={!isMobile ? textStyle : textStyleMobile}>
              {formatMessage(messages.helpMainTitleTop)}
            </span>
            <span style={!isMobile ? headerStyle : headerStyleMobile}>
              {formatMessage(messages.helpMainTitle)}
            </span>
          </div>
        ) : (
          <div style={{ marginLeft: !isMobile ? '12%' : '23%' }}>
            <span style={!isMobile ? textStyle : textStyleMobile}>
              {formatMessage(messages.resultHelpLabel)}
            </span>
            <span style={!isMobile ? headerStyle : headerStyleMobileSearched}>
              {searchedText}
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default HelpTopHeader;

const textStyle = {
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '21.78px',
  color: '#000000',
  display: 'block',
};
const textStyleMobile = {
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '21.78px',
  color: '#000000',
  display: 'block',
};

const headerStyle = {
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '33.89px',
  letterSpacing: '-2%',
  color: '#101828',
  display: 'block',
  marginTop: '-8px',
};

const headerStyleMobile = {
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '31.4px',
  letterSpacing: '-2%',
  color: '#101828',
};
const headerStyleMobileSearched = {
  fontWeight: 600,
  fontSize: '18pxx',
  color: '#101828',
  marginTop: '-5px',
  display: 'block',
};
