import React from 'react';
import Tippy from '@tippy.js/react';
import { Link } from 'gatsby';
import { useIntl } from 'react-intl';
import { useLocation } from '@reach/router';

import { Listing, ListingRow } from '../../../../components/Listing';
import { PassengerModel } from '../../../../models/PassengerModel';
import Chip from '../../../../components/Chip';
import AddIcon from '../../../../assets/icons/add-icon.inline.svg';
import PenIcon from '../../../../assets/icons/pen2-icon.inline.svg';
import Label from '../../../../components/fields/Label';
import translations from '../../../../translations/main.json';
import messages from './messages';
import { RequirementType } from '../../../DirectFeedbackUpload';
import { DossierItemCategory } from '../../../../models/DossierItemModel';
import TruncatedText from '../../../../components/TruncatedText';

export interface DocsInfoPassengerStatus {
  id: string;
  passenger: PassengerModel;
  status: 'pending' | 'failed' | 'completed';
  description: string[];
}

export interface DocsInfoGroupProps {
  id: RequirementType;
  title: string;
  description: string;
  docsInfoPassengerStatuses: DocsInfoPassengerStatus[];
  targetCategory: DossierItemCategory;
}

const docsInfoGroupToRouteMap = {
  [RequirementType.Authorization]: translations['cc4.seo.route.authorization'],
  [RequirementType.Identification]: translations['cc4.seo.route.upload-id'],
  [RequirementType.BookingConfirmation]:
    translations['cc4.seo.route.upload-booking-confirmation'],
  [RequirementType.BookingDocs]:
    translations['cc4.seo.route.upload-boarding-pass'],
  [RequirementType.PaymentReceipt]:
    translations['cc4.seo.route.upload-payment-receipt'],
  [RequirementType.DelayConfirmation]:
    translations['cc4.seo.route.upload-delay-confirmation'],
  [RequirementType.BirthCertification]:
    translations['cc4.seo.route.upload-birth-certificate'],
};

const DocsInfoGroup = ({
  id,
  title,
  description,
  docsInfoPassengerStatuses,
  targetCategory,
}: DocsInfoGroupProps): JSX.Element => {
  const { pathname } = useLocation();
  const { formatMessage } = useIntl();

  const isMobile = window.innerWidth < 617;

  const uploadDocumentPath = (passengerId: string) =>
    pathname.replace(
      /[a-z-]+\/?$/,
      `${translations['cc4.seo.route.passenger']}/${passengerId}/${docsInfoGroupToRouteMap[id]}`
    );

  return (
    <>
      <Label
        label={title}
        asHeader={true}
        tag={'h2'}
        explanation={description}
      />
      <Listing>
        {/* Column titles on desktop */}
        <ListingRow className="font-bold font-pt-sans hidden sm:flex">
          <div
            style={{
              width: '50%',
            }}
          >
            {formatMessage(messages.passengerColumnTitle)}
          </div>
          <div
            style={{
              width: '25%',
            }}
          >
            {formatMessage(messages.statusColumnTitle)}
          </div>
          <div
            style={{
              width: '15%',
            }}
          />
        </ListingRow>
        {docsInfoPassengerStatuses?.map?.(
          (passengerStatus): JSX.Element => (
            <ListingRow
              key={`key-${passengerStatus?.id}`}
              data-testid={'passengerRequirement'}
            >
              <div className="flex flex-row w-full sm:mt-0 px-10 sm:px-0">
                {/* Passenger name */}
                <div
                  className="sm:text-md xsd:flex-1"
                  style={{
                    width: isMobile ? '80%' : '57%',
                  }}
                >
                  <TruncatedText>
                    {`${passengerStatus?.passenger?.person?.first_name} ${passengerStatus?.passenger?.person?.last_name}`}
                  </TruncatedText>
                </div>
                {/* Status badges */}
                <div
                  className="w-full sm:w-auto text-center"
                  style={{
                    width: isMobile ? '37%' : '20%',
                  }}
                >
                  {passengerStatus.status === 'pending' ? (
                    <Chip color="danger">
                      {formatMessage(messages.requirementMissingStatus)}
                    </Chip>
                  ) : passengerStatus.status === 'failed' ? (
                    <span>
                      <Tippy
                        content={passengerStatus.description?.join?.('\n')}
                        className="text-left text-xxs"
                        placement="top"
                        arrow={true}
                      >
                        <span>
                          <Chip color="warning">
                            {formatMessage(messages.requirementRejectedStatus)}
                          </Chip>
                        </span>
                      </Tippy>
                    </span>
                  ) : null}
                </div>
              </div>
              {/* Add/Sign button */}
              <div
                style={{ width: isMobile ? undefined : '25%' }}
                className="text-right w-full sm:w-auto"
              >
                <Link
                  to={uploadDocumentPath(passengerStatus.passenger.id)}
                  state={{ categoryId: targetCategory?.id }}
                  className="button button-tiny button-link mt-15 sm:my-0 sm:p-0 w-full min-h-36 h-auto text-center sm:text-right border-solid border-green-400 sm:border-none sm:text-sm"
                >
                  {id === RequirementType.Authorization ? (
                    <PenIcon
                      viewBox="0 0 36 50"
                      height="20"
                      width="30"
                      className="mr-15 sm:mr-5 fill-primary"
                    />
                  ) : (
                    <AddIcon
                      viewBox="0 0 15 15"
                      height="18"
                      width="18"
                      className="mr-15 sm:mr-5 fill-primary"
                    />
                  )}
                  {formatMessage(
                    id === RequirementType.Authorization
                      ? messages.signDocumentButtonText
                      : messages.addDocumentButtonText
                  )}
                </Link>
              </div>
            </ListingRow>
          )
        )}
      </Listing>
    </>
  );
};

export default DocsInfoGroup;
