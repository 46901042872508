import React, { useEffect } from 'react';
import AccountContainer from '../../../components/AccountContainer';
import claimDetailsSideMenuItems from '../ClaimDetails/claimDetailsSideMenuItems';
import {
  useBuild,
  useJsonApi,
} from '../../../services/GlobalStore/GlobalStore';
import { getIdFromNumber } from '../../ClaimPage/Claim';
import getClaimFlightsSchedule from '../../../services/resources/claimGetters/filterFlightRoute';
import AccountFlights from './AccountFlights';
const B00KED = 'booked';
const ALTERNATIVE = 'alternative';

const FlightItinerary = ({ claimNumber }: { claimNumber: string }) => {
  const claimId = getIdFromNumber(claimNumber);
  const claim = useBuild('claims', claimId);

  const { data, get: getClaim } = useJsonApi(`/claims/${claimId}`, {
    queryParams: {
      include: [
        'customerFlights.airline',
        'customerFlights.departureAirport',
        'customerFlights.departureAirport.city',
        'customerFlights.arrivalAirport',
        'customerFlights.arrivalAirport.city',
        'claimValidationFlights.flight',
      ].join(),
    },
    cachePolicy: 'no-cache',
  });

  useEffect(() => {
    if (claimId) {
      getClaim();
    }
  }, [claimId, getClaim]);

  return (
    <AccountContainer
      menuItems={claimDetailsSideMenuItems(claim?.claim_number)}
    >
      {data?.length ? (
        <>
          <AccountFlights
            type={B00KED}
            flightsData={getClaimFlightsSchedule(data, B00KED)}
            claim={claim}
            showAddFlight={
              !claim.claim_validated || claim?.claim_validated === 'negative'
            }
          />
          <AccountFlights
            type={ALTERNATIVE}
            flightsData={getClaimFlightsSchedule(data, ALTERNATIVE)}
            claim={claim}
            showAddFlight={true}
          />
        </>
      ) : null}
    </AccountContainer>
  );
};

export default FlightItinerary;
