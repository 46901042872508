import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Form, Formik } from 'formik';
import * as yup from 'yup';

// Components
import Card from '../../components/Card';
import PasswordInput from '../../components/fields/PasswordInput';
import StepTitle from '../../components/StepTitle';
import Button from '../../components/Button';
import Alert from '../../components/Alert';

// Hooks
import { useGlobalState } from '../../services/GlobalStore/GlobalStore';
import { useHandleError } from '../../services/api';

// Resource
import { updatePassword } from '../../services/resources/accounts';

import messages from './messages';
import loginMessages from '../Login/messages';
import accountSideMenuItems from './accountSideMenuItems';
import AccountContainer from '../../components/AccountContainer';
import useAuthenticate from '../../hooks/useAuthenticate';

const validationSchema = yup.object().shape({
  current_password: yup.string().required(),
  password: yup.string().required(),
});

const ClientPassword = () => {
  const { formatMessage } = useIntl();
  const [handleError] = useHandleError();
  const { customerId } = useAuthenticate();
  const [account] = useGlobalState(`/accounts/me-${customerId}`);
  const [errors, setErrors] = useState(false);
  const [success, setSuccess] = useState(false);

  async function handleSubmit(values, { setSubmitting }) {
    setErrors(false);
    setSuccess(false);
    if (account?.id) {
      await updatePassword(account?.id, {
        ...values,
        country: { id: values.country },
      })
        .then(() => {
          setSuccess(true);
          setSubmitting(false);
        })
        .catch(error => {
          setSuccess(false);
          setSubmitting(false);
          handleError(error);
        });
    }
  }

  return (
    <AccountContainer menuItems={accountSideMenuItems}>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{}}
        validateOnMount
        validationSchema={validationSchema}
        isInitialValid={false}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <Card
              style={{
                paddingTop: 40,
                paddingBottom: 40,
                paddingLeft: 30,
                paddingRight: 30,
              }}
            >
              <StepTitle className={'mb-20'}>
                {formatMessage(messages.passwordTitle)}
              </StepTitle>
              <div className="fieldset sm:flex-row">
                <PasswordInput
                  name="current_password"
                  label={messages.currentPassword}
                  placeholder={messages.currentPasswordPlaceholder}
                />
              </div>
              <div className="fieldset sm:flex-row">
                <PasswordInput
                  name="password"
                  label={messages.newPassword}
                  placeholder={messages.newPasswordPlaceholder}
                />
              </div>
              {success ? (
                <Alert level="success" className="w-full my-20">
                  {formatMessage(loginMessages.setPasswordSuccess)}
                </Alert>
              ) : errors?.length > 0 ? (
                errors.map((error, index) => (
                  <Alert
                    // eslint-disable-next-line
                    key={`error-${index}`}
                    level="danger"
                    className="w-full my-20"
                  >
                    {error}
                  </Alert>
                ))
              ) : null}
              <Button
                theme="primary"
                type="submit"
                showIcon={false}
                className="mt-40 sm:w-170"
                disabled={!isValid || isSubmitting}
                isSubmitting={isSubmitting}
              >
                {formatMessage(messages.updatePassword)}
              </Button>
            </Card>
          </Form>
        )}
      </Formik>
    </AccountContainer>
  );
};

export default ClientPassword;
