import { defineMessages } from 'react-intl';

export default defineMessages({
  refund: {
    id: 'cc4.claims-overview.card.subtitle.refund',
    defaultMessage: 'Refund',
  },
  goToClaim: {
    id: 'cc4.claims-overview.complete-claim-button',
    defaultMessage: 'Complete',
  },
  moreDisruptedFlights: {
    id: 'cc4.claims-overview.more-disrupted-flights-text',
    defaultMessage:
      'More disrupted flights?  You can claim up to 5 years back!',
  },
  noStatus: {
    id: 'cc4.claims-overview.no-status',
    defaultMessage: 'No status',
  },
  claimNumber: {
    id: 'cc4.claims-overview.claim-number',
    defaultMessage: 'Claim #',
  },
});
