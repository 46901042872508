// React specific
import React, { useState } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
// Components
import SubTitleWithIcon from '../views/PersonalInfo/SubTitleWithIcon';
import { CSSTransition } from 'react-transition-group';
import Button from './Button';

// Icons
import CollapseIcon from '../assets/icons/collapse-icon.inline.svg';
import BinIcon from '../assets/icons/bin-icon.inline.svg';
import clsx from 'clsx';
// Default Translations
import collapsibleMessages from '../views/Account/ClaimDetails/ClaimPassengerList/collapsibleMessages';
const CollapsibleContainer = ({
  icon,
  subTitle,
  initalCollapsed = false,
  showMoreTitle = collapsibleMessages.claimPassengerMoreTitle,
  showLessTitle = collapsibleMessages.claimPassengerLessTitle,
  removeTitle = collapsibleMessages.claimPassengerRemoveTitle,
  removeButtonClassname = 'hidden sm:inline outline-none focus:outline-none button button-link mr-30',
  handleRemove,
  className,
  children,
}: {
  icon: any;
  subTitle: string;
  initalCollapsed?: boolean;
  showMoreTitle?: MessageDescriptor;
  showLessTitle?: MessageDescriptor;
  removeTitle?: MessageDescriptor;
  removeButtonClassname?: string;
  handleRemove: any;
  className?: string;
  children: any;
}) => {
  const [collapsed, setCollapsed] = useState(initalCollapsed);
  const { formatMessage } = useIntl();
  function handleCollapse() {
    setCollapsed(prevState => !prevState);
  }

  return (
    <div className={className}>
      <div className="flex flex-row justify-between">
        <SubTitleWithIcon
          icon={icon}
          iconClassName={'mr-18'}
          className="fill-primary text-lg"
        >
          {subTitle}
        </SubTitleWithIcon>
        <div className="inline-flex items-center cursor-pointer">
          {handleRemove ? (
            <button
              type="button"
              className={removeButtonClassname}
              // eslint-disable-next-line
              onClick={handleRemove}
            >
              <BinIcon
                alt="Trash icon"
                className={clsx('inline w-15 h-13 fill-primary')}
              />
              <span className={'text-sm text-primary-400'}>
                {formatMessage(removeTitle)}
              </span>
            </button>
          ) : null}
          <div
            className={'flex flex-row items-center'}
            onClick={handleCollapse}
          >
            <CollapseIcon
              className="mt-5 mr-5 inline h-15 sm:h-5"
              style={{
                transform: !collapsed ? 'rotate(180deg)' : '',
              }}
            />
            <span className={'text-sm hidden sm:inline'}>
              {!collapsed
                ? formatMessage(showMoreTitle)
                : formatMessage(showLessTitle)}
            </span>
          </div>
        </div>
      </div>
      <CSSTransition
        in={collapsed}
        // needs to be equal to the transition duration
        timeout={{
          appear: 300,
          enter: 300,
          exit: 300,
        }}
        classNames="display-"
        unmountOnExit={true}
        mountOnEnter={true}
        appear={true}
        enter={true}
        exit={true}
      >
        {/* div is neccessary that also fragements as childs receive the transition */}
        <div>{children}</div>
      </CSSTransition>
    </div>
  );
};

export default CollapsibleContainer;
