// React specific
import React, { useState } from 'react';

// Formik
import { Form, Formik } from 'formik';

// Validation
import '../../utils/yupIntlSetup';
import * as yup from 'yup';
import { validateBookingReference } from '../../utils/yupValidators';
import { Redirect } from '@reach/router';

// Translations
import { useIntl } from 'react-intl';
import messages from './messages';

// Components
import Container from '../../components/Container';
import Main from '../../components/Main';
import Card from '../../components/Card';
import StepTitle from '../../components/StepTitle';
import StepNavigation from '../../components/StepNavigation';
import Button from '../../components/Button';
import Aside from '../../components/Aside';
import Icon from '../../components/Icon';
import Input from '../../components/fields/Input';
import Seo from '../../components/seo';
import TrustBox from '../../components/TrustBox';
import InfoOverlay from '../../components/InfoOverlay';
import Alert from '../../components/Alert';

// Icons
import infoIcon from '../../assets/icons/info-icon-grey.svg';
import bookingConfirmationImage from '../../assets/icons/booking-reference.svg';

// Backend resources
import { useJsonApi } from '../../services/GlobalStore/GlobalStore';
import { addBookingNumbers } from '../../services/resources/claims';
import { useHandleError } from '../../services/api';
import { getIdFromNumber } from '../ClaimPage/Claim';
// Routes
import routes from '../../messages/routes';
import {
  steps,
  useTranslatedNavigate,
  translateRoute,
} from '../../utils/routes';

// Hooks
import useAuthenticate, { LoginSource } from '../../hooks/useAuthenticate';
import { useDocumentsNavigate } from '../../hooks/useDocumentsNavigate';
import { PageType } from '../Passengers/PassengerContainer';

const validationSchema = yup.object().shape({
  booking_number: validateBookingReference
    .min(5)
    .max(10)
    .required(),
});

const BookingConfirmation = ({
  pageType,
  claimNumber,
  activeClaim,
}: {
  pageType: PageType;
  claimNumber?: string;
  activeClaim?: any;
}) => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('');

  const { formatMessage } = useIntl();
  const translatedNavigate = useTranslatedNavigate();
  const claimId: string | undefined | null =
    localStorage.getItem('yo:claimId') || getIdFromNumber(claimNumber);
  const [handleError] = useHandleError();
  const { token, isLoggedIn } = useAuthenticate();
  const { navigateBack } = useDocumentsNavigate({ claimId, pageType });
  const isAccountPage = pageType === PageType.Account;
  const regex = /^(([A-Za-z]{2,3})|([A-Za-z]\d)|(\d[A-Za-z]))\s*?(\d{1,})([A-Za-z]?)$/;

  function handleSubmit(values, { setSubmitting }) {
    const passengers = claim?.[0]?.passengers;
    addBookingNumbers({ passengers, values, token })
      .then(() => nextStep(setSubmitting))
      .catch(error => {
        handleError(error);
        setSubmitting(false);
      });
  }

  function nextStep(setSubmitting) {
    if (pageType === PageType.Claim) {
      translatedNavigate(routes[steps.ThankYou]);
    } else if (isLoggedIn && !isAccountPage) {
      translatedNavigate(['/', routes.account]);
    } else if (isAccountPage) {
      setAlertType('success');
      setShowAlert(true);
      setSubmitting(false);
    } else {
      translatedNavigate(['/', routes.login], {
        state: { source: LoginSource.Register },
      });
    }
  }

  const { data: claim } = useJsonApi(
    `claims/${claimId}`,
    {
      credentials: 'include',
      cachePolicy: 'no-cache',
      queryParams: {
        include: 'passengers',
      },
      headers: { Authorization: `Bearer ${token}` },
    },
    [claimId]
  );

  // Find booking number by index in case passenger with index 1 has the boooking number

  const findBookingtNumber = (obj: any) => obj.booking_number?.length;

  if (
    claim?.length &&
    claim[0]?.passengers?.findIndex(findBookingtNumber) >= 0 &&
    !isAccountPage
  ) {
    const nextPath = [
      '',
      ...(pageType === PageType.Claim
        ? [
            translateRoute(routes[steps.Claim]),
            claim[0].claim_number,
            translateRoute(routes[steps.ThankYou]),
          ]
        : ['/', translateRoute(routes.account)]),
    ];

    return <Redirect to={nextPath.join('/')} noThrow={true} />;
  }

  const findBookingNumberPassenger = () => {
    if (activeClaim?.id) {
      const findPassengerIndex = activeClaim?.passengers?.findIndex(
        (passenger: any) => passenger.booking_number
      );

      if (findPassengerIndex >= 0) {
        return activeClaim.passengers[findPassengerIndex]?.booking_number;
      }
    }
    return '';
  };

  return (
    <Formik
      initialValues={{ booking_number: findBookingNumberPassenger() }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnMount={true}
    >
      {({ values }) => (
        <Container>
          <Seo
            title={messages.seoTitle}
            description={messages.seoDescription}
          />
          <Main>
            <Form>
              <Card
                onlyDesktop={true}
                style={{ paddingBottom: isAccountPage ? 0 : '' }}
              >
                <div className="flex justify-center sm:justify-between items-center text-center">
                  <StepTitle>{formatMessage(messages.header)}</StepTitle>
                  <InfoOverlay
                    title={messages.header}
                    description={messages.asideText}
                  />
                </div>
                <Card onlyMobile={true}>
                  <div className="mt-0 sm:-mt-10 mb-5 sm:flex items-start">
                    <p className="mt-0 mb-20 sm:mr-20">
                      {formatMessage(messages.explanation)}
                    </p>
                    <img
                      className="pnr-img w-full sm:w-auto sm:-mt-20 mb-20"
                      src={bookingConfirmationImage}
                      alt={formatMessage(messages.imageAlternative)}
                      aria-hidden="true"
                    />
                  </div>
                  <Input
                    name="booking_number"
                    label={formatMessage(messages.pnrLabel)}
                    placeholder={formatMessage(messages.pnrPlaceholder)}
                    disabled={isAccountPage && !!findBookingNumberPassenger()}
                  />
                  {regex.test(values?.booking_number) ? (
                    <div className="mt-10">
                      <Alert level={'danger'}>
                        {formatMessage(messages.explanationFlightNumber)}
                      </Alert>
                    </div>
                  ) : null}
                  {isAccountPage && (
                    <>
                      <StepNavigation
                        dataLayerPushOnBack={false}
                        dataLayerPushOnContinue={true}
                        submitButtonMessage={formatMessage(
                          messages.updateButtonText
                        )}
                        showIcon={false}
                        disabled={!!findBookingNumberPassenger()}
                      />
                      {showAlert && (
                        <Alert
                          level={alertType}
                          className="w-full my-20"
                          setCloseIcon={setShowAlert}
                          iconClassName="cursor-pointer absolute right-80"
                        >
                          {alertType === 'success'
                            ? formatMessage(messages.successMessage)
                            : formatMessage(messages.successMessage)}
                        </Alert>
                      )}
                    </>
                  )}
                </Card>
              </Card>
              {!isAccountPage && (
                <StepNavigation
                  onBackClick={navigateBack}
                  backButtonMessage={formatMessage(messages.backButtonText)}
                  submitButtonMessage={formatMessage(messages.submitButtonText)}
                  dataLayerPushOnBack={true}
                  dataLayerPushOnContinue={true}
                  step={steps.BookingReference}
                >
                  <Button theme="info" type="button" onClick={nextStep}>
                    {formatMessage(messages.laterButtonText)}
                  </Button>
                </StepNavigation>
              )}
            </Form>
          </Main>
          {!isAccountPage && (
            <>
              <Aside>
                <Icon src={infoIcon} className="mb-20" />
                {formatMessage(messages.asideText)}
              </Aside>
              <TrustBox />
            </>
          )}
        </Container>
      )}
    </Formik>
  );
};

export default BookingConfirmation;
