import React from 'react';

// Navigation
import { Redirect } from '@reach/router';
import routes from '../../messages/routes';
import { steps, translateRoute } from '../../utils/routes';

// Backend service
import { useJsonApi } from '../../services/GlobalStore/GlobalStore';

const RedirectAfterLogin = (): JSX.Element | null => {
  const { data: claims, isLoaded: claimsLoaded } = useJsonApi(
    `/claims`,
    {
      cachePolicy: 'no-cache',
      queryParams: {
        include: [
          'customer',
          'placeOfJurisdiction',
          'customerFlights.airline',
          'customerFlights.departureAirport',
          'customerFlights.departureAirport.city',
          'customerFlights.arrivalAirport',
          'customerFlights.arrivalAirport.city',
        ].join(),
      },
    },
    []
  );

  if (!claimsLoaded) {
    return null;
  } else {
    if (claims?.length > 1) {
      return (
        <Redirect
          to={translateRoute([
            '/',
            routes[steps.Account],
            routes[steps.ClaimsOverview],
          ])}
          noThrow={true}
        />
      );
    } else if (claims?.length === 1) {
      return (
        <Redirect
          to={translateRoute([
            '/',
            routes[steps.Account],
            routes[steps.Claim],
            claims[0].claim_number,
          ])}
          noThrow={true}
        />
      );
    } else {
      return (
        <Redirect
          to={translateRoute([
            '/',
            routes[steps.Account],
            routes[steps.Profile],
          ])}
          noThrow={true}
        />
      );
    }
  }
};

export default RedirectAfterLogin;
