import React from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from '@reach/router';

import Label from '../../../../components/fields/Label';
import { Listing, ListingRow } from '../../../../components/Listing';
import { Link } from 'gatsby';
import AddIcon from '../../../../assets/icons/add-icon.inline.svg';
import translations from '../../../../translations/main.json';
import messages from './messages';

const OtherDocumentsGroup = () => {
  const { formatMessage } = useIntl();
  const { pathname } = useLocation();

  const uploadOtherDocumentPath = pathname.replace(
    /[a-z-]+\/?$/,
    `${translations['cc4.seo.route.upload-other-documents']}`
  );

  return (
    <>
      <Label
        label={messages.otherDocumentsTitle}
        asHeader={true}
        tag={'h2'}
        explanation={messages.otherDocumentsDescription}
      />
      <Listing>
        <ListingRow>
          <div className="w-full text-center">
            <Link
              to={uploadOtherDocumentPath}
              className="button button-tiny button-link my-20 sm:my-0 sm:p-0 w-full min-h-36 h-auto text-center border-solid border-green-400 sm:border-none sm:text-sm"
            >
              <AddIcon
                viewBox="0 0 15 15"
                height="18"
                width="18"
                className="mr-15 sm:mr-5 fill-primary"
              />
              {formatMessage(messages.addMoreFilesButton)}
            </Link>
          </div>
        </ListingRow>
      </Listing>
    </>
  );
};

export default OtherDocumentsGroup;
