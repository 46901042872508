import React from 'react';
import AccountContainer from '../../../components/AccountContainer';
import claimDetailsSideMenuItems from '../ClaimDetails/claimDetailsSideMenuItems';
import { useBuild } from '../../../services/GlobalStore/GlobalStore';
import { getIdFromNumber } from '../../ClaimPage/Claim';
import BookingConfirmation from '../../BookingNumber';
import TicketNumber from './TicketNumber';
import { PageType } from '../../Passengers/PassengerContainer';

const BookingDetails = ({ claimNumber }: { claimNumber: string }) => {
  const claimId = getIdFromNumber(claimNumber);

  const claim = useBuild('claims', claimId);

  return (
    <AccountContainer
      menuItems={claimDetailsSideMenuItems(claim?.claim_number)}
    >
      {claim?.id && (
        <>
          <BookingConfirmation
            pageType={PageType.Account}
            claimNumber={claimNumber}
            activeClaim={claim}
          />
          <TicketNumber claim={claim} />
        </>
      )}
    </AccountContainer>
  );
};

export default BookingDetails;
