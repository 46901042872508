import React from 'react';

import Loading from '../../../../components/Loading';

import { useBuild } from '../../../../services/GlobalStore/GlobalStore';

import { getIdFromNumber } from '../../../ClaimPage/Claim';

import UploadedDocuments from './UploadedDocuments';
import AccountContainer from '../../../../components/AccountContainer';
import claimDetailsSideMenuItems from '../claimDetailsSideMenuItems';
import ClaimRequirements from './ClaimRequirements';
import TermsConditions from './TermsAndConditions';

const ClaimDocuments = ({ claimNumber }: { claimNumber: string }) => {
  const claimId = getIdFromNumber(claimNumber);
  const claim = useBuild('claims', claimId);

  if (!claim) return <Loading />;

  return (
    <AccountContainer
      menuItems={claimDetailsSideMenuItems(claim?.claim_number)}
    >
      <ClaimRequirements claimId={claimId} />
      <UploadedDocuments claimId={claimId} />
      <TermsConditions claimId={claimId} />
    </AccountContainer>
  );
};

export default ClaimDocuments;
