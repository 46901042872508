import routes from '../../../messages/routes';
import bulbIcon from '../../../assets/icons/bulb-icon.inline.svg';
import inboxIcon from '../../../assets/icons/inbox-icon.inline.svg';
import messages from './../messages';

const supportSideMenuItems = [
  {
    to: routes.helpCenter,
    icon: bulbIcon,
    basepath: routes.account,
    title: messages.helpCenter,
  },
  {
    to: routes.messages,
    icon: inboxIcon,
    basepath: routes.account,
    title: messages.messagesPageLink,
  },
];

export default supportSideMenuItems;
