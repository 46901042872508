import React, { useEffect, useState } from 'react';
// Components
import AccountContainer from '../../../components/AccountContainer';
import Card from '../../../components/Card';
import HelpFooter from './HelpFooter';
import HelpContent from './HelpContent';
import { useJsonApi } from '../../../services/GlobalStore/GlobalStore';
import QuestionRow from './HelpContent/QuestionRow';
import { isEmpty } from 'lodash';

const DIVIDER_STYLE = {
  marginTop: '5%',
  borderBottom: '1px solid #d9dbe9',
};

const HelpByQuestion = (props: any) => {
  const [searchedText, setSearchedText] = useState('');
  const [foundAnswers, setFoundAnswers] = useState([]);

  const { data } = useJsonApi(
    '/faqCategories',
    {
      credentials: 'include',
      cachePolicy: 'no-cache',
      queryParams: {
        include: 'faqs',
      },
    },
    []
  );

  useEffect(() => {
    const text = props?.location?.state?.searchedText || '';
    const question = props?.location?.state?.question;
    const answers: any =
      Array.isArray(question) && question.length
        ? question
        : !isEmpty(question)
        ? [question]
        : [];
    setSearchedText(text);
    setFoundAnswers(answers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.location?.state?.question]);

  return (
    <AccountContainer hasSideBarComponent={true}>
      <Card>
        {data ? (
          <HelpContent faqCategories={data} searchedText={searchedText} />
        ) : null}
        {foundAnswers?.length
          ? foundAnswers?.map((item: any) => (
              <QuestionRow
                key={item?.id}
                question={item?.question}
                answer={item?.answer}
                hasSearchedText={!!searchedText.length}
                resultsLength={foundAnswers?.length}
              />
            ))
          : null}
        <div style={DIVIDER_STYLE} />
        <HelpFooter searchedText={searchedText} />
      </Card>
    </AccountContainer>
  );
};

export default HelpByQuestion;
