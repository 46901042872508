// React specific
import React, { useCallback, useState, useEffect, useRef } from 'react';
import { format } from 'date-fns';

// Formik
import { Form, Formik } from 'formik';
import Main from '../../../components/Main';
import Container from '../../../components/Container';

// Validation
import * as yup from 'yup';

// Components
import Alert from '../../../components/Alert.js';
import Card from '../../../components/Card';
import StepTitle from '../../../components/StepTitle.js';
import AccountContainer from '../../../components/AccountContainer';
import supportSideMenuItems from './supportSideMenuItems';
import Button from '../../../components/Button';
import TextArea from '../../../components/fields/TextArea';
import NativeSelect from '../../../components/fields/NativeSelect';
import SeparatorWithText from '../../../components/SeparatorWithText';
import SubTitleWithIcon from '../../PersonalInfo/SubTitleWithIcon';
import MessageBubble, {
  MessageSender,
} from '../../../components/MessageBubble';
import FiltersDropdown from '../../../components/FiltersDropdown';
import Loading from '../../../components/Loading';

// Translation
import { useIntl } from 'react-intl';
import messages from '../messages';

// Hooks
import { useHandleError, deserializeJSONAPI } from '../../../services/api';

// Icons
import newMessageIcon from '../../../assets/icons/new-message-icon.inline.svg';
import ArrowUpIcon from '../../../assets/icons/arrow-up-icon.inline.svg';
import filterIcon from '../../../assets/icons/filter-icon.svg';

// API Calls
import { sendSupportMessage } from '../../../services/resources/supportMessages';
import { useJsonApi } from '../../../services/GlobalStore/GlobalStore';
import useFetch from 'use-http';

// Models
import { SupportChatItemModel } from '../../../models/SupportChatItemModel';
import { SupportChatCategoryModel } from '../../../models/SupportChatCategoryModel';
import { SupportChatChildrenCategoryModel } from '../../../models/SupportChatChildrenCategoryModel';
import clsx from 'clsx';

const validationSchema = yup.object().shape({
  reference: yup.string().required(),
  topic: yup.string().required(),
  message: yup.string().required(),
});

// Need for scroll loading messages --> http://api.yource.test/supportMessages?page[size]=10

const SupportMessagesOverview = () => {
  const { formatMessage } = useIntl();
  const [handleError] = useHandleError();
  const [openForm, setOpenForm] = useState(false);
  const [filterClaimOption, setFilterClaimOption] = useState(undefined);
  const [pageCounter, setPageCounter] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [messagesStatus, setMessagesStatus] = useState(false);
  const messageScrollContainer = useRef<HTMLDivElement>();
  const [claimId, setClaimId] = useState<string | null>(null);

  function setPageCounterDueToCalculation() {
    const container = messageScrollContainer?.current;
    if (container) {
      if (
        container.scrollTop ===
          container.scrollHeight - container.offsetHeight &&
        pageCounter < totalPages
      ) {
        const nextPage = pageCounter + 1;
        setPageCounter(nextPage);
      }
    }
  }

  function setOpenFormValue() {
    setOpenForm(!openForm);
    setMessagesStatus(false);
  }

  const setFilter = useCallback(
    (option: { label: string; value: string | null | undefined }) => {
      // Its okay to assign here undefined or null
      setFilterClaimOption(option.value);
    },
    []
  );

  const [chatMessagesList, setChatMessagesList] = useState<
    SupportChatItemModel[]
  >([]);
  const [chatCategories, setChatCategories] = useState<
    SupportChatCategoryModel[]
  >([]);
  // Get claim information for filling the reference list (select fields)
  const { data: claims } = useJsonApi(
    `/claims`,
    {
      credentials: 'include',
      cachePolicy: 'no-cache',
      queryParams: {
        include: ['customer', 'customerFlights.airline', 'flights.airline'],
        sort: '-created_at',
      },
    },
    []
  );

  // Get all support Categories and their children categories
  const {
    data: categories,
    loading: isLoadingCategories,
    isLoaded: isLoadedCategories,
    get: getCategories,
  } = useJsonApi(`/supportMessageCategories`, {
    credentials: 'include',
    cachePolicy: 'no-cache',
  });

  useEffect(() => {
    if (!isLoadingCategories) {
      if (claimId) {
        getCategories(`?filter[claim.id]=${claimId}&include=children`);
      } else {
        getCategories(`?include=children`);
      }
    }
    // eslint-disable-next-line
  }, [claimId]);

  // Load filter and pageCounter specific messages
  async function loadMessages() {
    if (filterClaimOption) {
      await get(
        `?filter[claim.id]=${filterClaimOption}&page[size]=10&page[number]=${pageCounter}&include=claim`
      );
    } else {
      if (filterClaimOption === null) {
        await get(
          `?filter[claim.id]=null&page[size]=10&page[number]=${pageCounter}&include=claim`
        );
      } else {
        await get(`?page[size]=10&page[number]=${pageCounter}&include=claim`);
      }
    }
  }

  const { get, data, loading: isLoadedChatMessages } = useFetch(
    `/supportMessages`,
    {
      credentials: 'include',
      cachePolicy: 'no-cache',
    }
  );

  useEffect(() => {
    if (pageCounter === 1) {
      loadMessages();
    } else {
      setPageCounter(1);
    }
  }, [filterClaimOption]);

  useEffect(() => {
    loadMessages();
  }, [pageCounter]);

  useEffect(() => {
    if (data) {
      // Set the total numbers of chat pages
      setTotalPages(data?.meta?.pagination?.total_pages);
      const deserializedData = deserializeJSONAPI(data);
      if (deserializedData) {
        deserializedData
          .then((messageData: SupportChatItemModel[]) => {
            if (pageCounter === 1) {
              setChatMessagesList(messageData);
            } else {
              setChatMessagesList(prevState => {
                return prevState.concat(messageData);
              });
            }
          })
          .catch(error => handleError(error));
      } else {
        setChatMessagesList([]);
      }
    }
  }, [data]);

  useEffect(() => {
    if (categories) {
      setChatCategories(categories);
    }
  }, [isLoadedCategories]);

  useEffect(() => {
    const chatbot = document.getElementById('chatbot');
    if (chatbot && chatbot.className) {
      chatbot.className = chatbot.className.replace('hidden', 'block');
      return () => {
        chatbot.className = chatbot.className.replace('block', 'hidden');
      };
    }
  }, []);

  const config = null;
  const referenceOptions: { label: string; value: string | null }[] = [];
  type category = {
    label: string;
    value: string;
  };
  const childrenToParentCategorieMap: {
    parent: category;
    children: category[];
  }[] = [];

  claims?.map((claim: {}) => {
    const airline =
      claim?.customerFlights[0]?.airline?.name ||
      claim?.flights[0]?.airline?.name;
    const date =
      claim?.customerFlights[0]?.flight_date ||
      claim?.flights[0]?.scheduled_gate_departure_date;

    referenceOptions.push({
      label: `${claim.claim_number} - ${airline} - ${format(
        new Date(date),
        'dd/MM/yyyy'
      )}`,
      value: claim.id,
    });
  });

  chatCategories?.map((categorieItem: SupportChatCategoryModel) => {
    // Fill first the children categorie for the parentCategorie
    const childrenCategories: category[] = [];
    categorieItem.children.map(
      (childrenCategorie: SupportChatChildrenCategoryModel) => {
        childrenCategories.push({
          label: childrenCategorie.name,
          // Backend just need the parent id (depends on the buisness case)
          value: childrenCategorie.id,
        });
      }
    );
    // Fill up the map which is needed for the NativeSelect component
    childrenToParentCategorieMap.push({
      parent: {
        label: categorieItem.name,
        value: categorieItem.id,
      },
      children: childrenCategories,
    });
  });

  const initialValues = {
    reference: '',
    topic: '',
    message: '',
  };

  function handleSubmit(values, { setSubmitting, resetForm }) {
    const claimFilterRef = isNaN(values.reference) ? null : values.reference;
    const message = values.message;
    sendSupportMessage(
      {
        message,
        type: 'supportMessages',
        claim: {
          ...(isNaN(values.reference) ? {} : { id: values.reference }),
        },
        category: { id: values.topic },
      },
      config
    )
      .then(() => {
        setSubmitting(false);
        setFilterClaimOption(prevState => {
          if (prevState !== claimFilterRef) {
            setFilterClaimOption(claimFilterRef);
          } else {
            setFilterClaimOption(claimFilterRef);
            loadMessages();
          }
        });
        setOpenForm(false);
        setMessagesStatus(true);
        resetForm({});
      })
      .catch(error => {
        handleError(error);
        setSubmitting(false);
        setMessagesStatus(false);
      });
  }

  return (
    <AccountContainer menuItems={supportSideMenuItems}>
      <Card>
        <StepTitle
          style={{
            marginBottom: '0.2rem',
          }}
          className={'text-black text-24 smd:m-0'}
        >
          {formatMessage(messages.messagesTitle)}
        </StepTitle>
        <p className={'font-open-sans text-base mb-30 mt-0'}>
          {formatMessage(messages.messagesSubtitle)}
        </p>
        <div
          className={clsx('flex flex-col justify-start', {
            ['py-30']: messagesStatus,
          })}
        >
          <button
            onClick={setOpenFormValue}
            className={
              'outline-none focus:outline-none flex flex-row items-center border-none pl-0 justify-start focus:outline-none'
            }
          >
            <SubTitleWithIcon
              icon={newMessageIcon}
              width={40}
              className={'text-primary-400'}
            >
              {formatMessage(messages.messagesNewMessageButtonTitle)}
            </SubTitleWithIcon>
            <ArrowUpIcon
              style={{
                transform: openForm ? 'rotate(180deg)' : '',
              }}
              className="fill-primary cursor-pointer top-0 bottom-0 right-0 m-15"
            />
          </button>
          {messagesStatus ? (
            <Alert className={'mt-30'} level="success">
              <p className={'p-0 m-0'}>
                {formatMessage(messages.messagesMessageStatus)}
              </p>
            </Alert>
          ) : null}
        </div>
        {openForm ? (
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            isInitialValid={false}
            validateOnMount={true}
            validationSchema={validationSchema}
          >
            {({ isSubmitting, isValid }) => (
              <Container
                style={{
                  marginTop: '1.25rem',
                }}
              >
                <Main>
                  <Form className={'flex flex-col mb-30 bg-primary-05'}>
                    <div
                      className={
                        'flex flex-col sm:flex-row justify-between p-30'
                      }
                    >
                      <NativeSelect
                        className={'mb-15 sm:m-0'}
                        name="reference"
                        options={referenceOptions}
                        label={messages.messagesReferenceDropdownTitle}
                        placeholder={
                          messages.messagesReferenceDropdownPlaceholder
                        }
                        // eslint-disable-next-line
                        onChangeCallback={(value: string) => {
                          setClaimId(value);
                        }}
                      />
                      <NativeSelect
                        name="topic"
                        options={childrenToParentCategorieMap}
                        label={messages.messagesTopicDropdownTitle}
                        placeholder={
                          !claimId
                            ? messages.messagesTopicDropdownDisabledPlaceholder
                            : messages.messagesTopicDropdownPlaceholder
                        }
                        disabled={!claimId}
                      />
                    </div>
                    <TextArea
                      name="message"
                      type="text"
                      rows={'5'}
                      cols={'3'}
                      label={messages.messagesMessageTextBoxTitle}
                      placeholder={messages.messagesMessageTextBoxPlaceholder}
                      className={
                        'resize-none px-30 sm:max-w-none max-w-none sm:max-h-none max-h-none'
                      }
                    />
                    <Button
                      type={'submit'}
                      className={
                        'm-20 w-full sm:max-w-140 p-20 self-center w-auto'
                      }
                      theme="primary"
                      size="small"
                      showIcon={false}
                      disabled={!isValid || isSubmitting}
                      isSubmitting={isSubmitting}
                    >
                      {formatMessage(messages.messagesMessageSendButton)}
                    </Button>
                  </Form>
                </Main>
              </Container>
            )}
          </Formik>
        ) : null}
        <SeparatorWithText
          text={formatMessage(messages.messagesHeaderStripeTitle)}
          textClassName={
            'text-center text-base text-gray-300 font-bold font-pt-sans'
          }
          separatorClassName={'m-0 my-25'}
        />
        <div className={'flex item-center justify-end w-full'}>
          <FiltersDropdown
            icon={filterIcon}
            btnTitle={formatMessage(messages.messagesMessageFilterTitle)}
            btnDisabled={false}
            fixedOptionsOnTop={[
              {
                label: formatMessage(messages.messagesMessageAllMessagesTag),
                value: undefined,
              },
            ]}
            options={referenceOptions}
            handleOnOptionClicked={setFilter}
          />
        </div>
        {chatMessagesList.length > 0 ? (
          <div
            ref={messageScrollContainer}
            onScroll={setPageCounterDueToCalculation}
            className={
              'flex flex-col overflow-y-scroll overflow-x-hidden max-h-680 mt-30 p-10'
            }
          >
            {chatMessagesList?.map((chat: SupportChatItemModel) => {
              return (
                <MessageBubble
                  key={chat.id}
                  messageText={chat.message}
                  messageTag={
                    chat.claim
                      ? chat.claim?.claim_number
                      : formatMessage(messages.messagesMessageGeneralTag)
                  }
                  authorName={chat.author_name}
                  sentAt={chat.created_at}
                  sender={
                    chat.direction === 'sent'
                      ? MessageSender.CUSTOMER
                      : MessageSender.YOURCE
                  }
                />
              );
            })}
          </div>
        ) : (
          <p>{formatMessage(messages.messagesMessageNoMessageFoundText)}</p>
        )}

        {isLoadedChatMessages ? (
          <div className={'flex items-center justify-center'}>
            <Loading />
          </div>
        ) : null}
      </Card>
    </AccountContainer>
  );
};

export default SupportMessagesOverview;
