import React from 'react';
import Tippy from '@tippy.js/react';

const TruncatedText = ({
  children,
  maxLength = 40,
}: {
  children: string | null;
  maxLength?: number;
}): JSX.Element => {
  if (!children || children?.length <= maxLength) return <>{children}</>;

  return (
    <Tippy
      content={children}
      className="w-full text-left"
      placement="top"
      arrow={true}
    >
      <div className="truncate">{children}</div>
    </Tippy>
  );
};

export default TruncatedText;
