// Translations
import flightDisruptionMessages from '../../../messages/flightDisruptionMessages';
import { CustomerFlightModel } from '../../../models/CustomerFlightModel';
import { ValidationFlightModel } from '../../../models/ValidationFlightModel';
import claimOverviewMessages from '../../../views/Account/ClaimsOverview/claimOverviewMessages';

const getTranslatedClaimFlightStatus = (claimData: {}): {
  id: string;
  defaultMessage: string;
} => {
  const flightStatus = getClaimFlightStatus(claimData);
  if (flightStatus) return flightDisruptionMessages[flightStatus];

  // If nothing found
  return claimOverviewMessages.noStatus;
};

/**
 * it should first use:
 * The status of the problem flight of the validated flight
 * Otherwise the status of the first booked validated flight
 * Otherwise the status of the first booked customer flight
 */
const getClaimFlightStatus = (claimData: {}) => {
  /**
   * LOGIC TO GET THE CORRECT FLIGHT STATUS FROM BACKEND DATA
   */
  // The most accurate status is the problem type of the problem flight which is a validation flight of the booked schedule.
  const problemFlight = claimData?.flights?.find(
    (flight: ValidationFlightModel) =>
      flight?.meta?.pivot?.type === 'booked' &&
      flight?.meta?.pivot?.is_problem_flight === 1
  );

  if (problemFlight?.problem_type) return problemFlight?.problem_type;
  if (problemFlight?.flight_status) return problemFlight?.flight_status;

  const bookedFlightStatus = claimData?.flights?.find(
    (flight: ValidationFlightModel) => flight?.meta?.pivot?.type === 'booked'
  );
  if (bookedFlightStatus?.flight_status)
    return bookedFlightStatus?.flight_status;

  // If not available we look at the flight status of the first customer flight of the booked schedule.
  const customerFlightStatus = claimData?.customerFlights?.find(
    (flight: CustomerFlightModel) => flight?.type === 'booked'
  )?.flight_status;
  if (customerFlightStatus) return customerFlightStatus;
};

export { getClaimFlightStatus };

export default getTranslatedClaimFlightStatus;
