import { defineMessages } from 'react-intl';

export default defineMessages({
  seoTitle: {
    id: 'cc4.seo.booking-confirmation.title',
    defaultMessage: '',
  },
  seoDescription: {
    id: 'cc4.seo.booking-confirmation.description',
    defaultMessage: '',
  },
  header: {
    id: 'cc4.form.booking-confirmation.header-text',
    defaultMessage: 'Enter Booking Confirmation',
  },
  explanation: {
    id: 'cc4.form.booking-confirmation.explanation',
    defaultMessage:
      'You can find your booking confirmation code (PNR or booking reference number) on your E-ticket or any email received from the airline.',
  },
  imageAlternative: {
    id: 'cc4.form.booking-confirmation.image-alternative',
    defaultMessage: 'Booking confirmation',
  },
  pnrLabel: {
    id: 'cc4.form.booking-confirmation.label.pnr',
    defaultMessage: 'Booking code',
  },
  pnrPlaceholder: {
    id: 'cc4.form.booking-confirmation.pnr-placeholder',
    defaultMessage: 'e.g. P5CWHY',
  },
  asideText: {
    id: 'cc4.form.booking-confirmation.aside-text',
    defaultMessage:
      'This code is often six characters, including letters and digits',
  },
  backButtonText: {
    id: 'cc4.form.booking-confirmation.back-button-text',
    defaultMessage: 'Back',
  },
  laterButtonText: {
    id: 'cc4.form.booking-confirmation.later-text',
    defaultMessage: "I'll do it later",
    description: 'Text on button to enter PNR later',
  },
  submitButtonText: {
    id: 'cc4.form.booking-confirmation.submit-button-text',
    defaultMessage: 'Continue',
  },
  updateButtonText: {
    id: 'cc4.form.booking-confirmation.update',
    defaultMessage: 'Update',
  },
  successMessage: {
    id: 'cc4.form.booking-confirmation.success',
    defaultMessage: 'Booking number has been updated succesfully!',
  },
  dangerMessage: {
    id: 'cc4.form.ticket-number.danger',
    defaultMessage: 'Something went wrong please try again!',
  },
  explanationFlightNumber: {
    id: 'cc4.form.booking-confirmation.explanation-flight-number',
    defaultMessage:
      'You’ve probably entered your flight number in the PNR (booking confirmation number) field below. Please check your booking documents/boarding pass to fill in the right number. This is needed to confirm your booking with the airline.',
  },
});
