import React, { FunctionComponent } from 'react';

// Components
import ButtonWithButtonTextAnimation from '../../../../components/ButtonWithButtonTextAnimation';
import NativeWebShareButton from '../../../../components/NativeWebShareButton';
import { ListingRow } from '../../../../components/Listing';

import { PassengerModel } from '../../../../models/PassengerModel';

// Translation
import messages from './messages';
import { useIntl } from 'react-intl';
import { useCoBrandingParams } from '../../../../hooks/useCobrandingParams';

interface PassengerShareItemProps {
  claim: {};
  passenger: PassengerModel;
}

const PassengerShareItem: FunctionComponent<PassengerShareItemProps> = ({
  claim,
  passenger,
}: PassengerShareItemProps) => {
  const { formatMessage } = useIntl();
  const { dynamicConfig } = useCoBrandingParams();

  const isMobile = window.innerWidth < 617;

  function handleSendMail() {
    const dynamicMailBodyTextValues = {
      passenger_fullname:
        passenger?.person?.first_name + ' ' + passenger?.person?.last_name,
      product_type: claim.product_type,
      claim_site_name: dynamicConfig?.siteName,
      claim_airline_name: claim.liableOrganization?.name,
      link: passenger.person?.shareablePassengerPageUrl?.url,
      claim_customer_full_name:
        claim.customer.firstname + ' ' + claim.customer.lastname,
    };

    window.location.href = passenger?.person?.email
      ? `mailto:${passenger?.person?.email}`
      : 'mailto:' +
        `?body=${encodeURIComponent(
          formatMessage(
            messages.sharePassengerMailBodyText,
            dynamicMailBodyTextValues
          )
        )}` +
        '&subject=' +
        formatMessage(
          messages.sharePassengerMailSubjectText,
          dynamicMailBodyTextValues
        );
  }

  function handleCopyLink() {
    // Doesn't work on not secure environment
    navigator.clipboard.writeText(
      passenger.person.shareablePassengerPageUrl.url
    );
  }

  return isMobile ? (
    <ListingRow key={passenger.id} className="font-pt-sans flex">
      <p
        className="sm:text-md p-0 mb-25 mt-0 w-full"
        style={{ textAlign: isMobile ? 'center' : 'left' }}
      >
        {passenger?.person?.first_name + ' ' + passenger?.person?.last_name}
      </p>
      {passenger.person?.shareablePassengerPageUrl?.url ? (
        <NativeWebShareButton
          url={passenger.person.shareablePassengerPageUrl.url}
          buttonText={formatMessage(
            messages.sharePassengerSendMailMobileButtonTitle
          )}
        />
      ) : null}
    </ListingRow>
  ) : (
    <ListingRow key={passenger.id} className="px-10 py-20 sm:px-30 sm:py-0">
      <p
        className="flex-1 p-0 mt-0"
        style={{ textAlign: isMobile ? 'center' : 'left' }}
      >
        {passenger?.person?.first_name + ' ' + passenger?.person?.last_name}
      </p>

      <button
        className="font-pt-sans w-full items-center flex justify-center rounded-full bg-primary-400 text-white border p-5 sm:px-30 text-xs font-bold outline-none focus:outline-none sm:w-auto mt-10 sm:mt-0"
        onClick={handleSendMail}
        type="button"
      >
        {formatMessage(messages.sharePassengerSendMailButtonTitle)}
      </button>

      <ButtonWithButtonTextAnimation
        secondButtonText={formatMessage(
          messages.sharePassengerCopiedLinkButtonTitle
        )}
        firstButtonText={formatMessage(
          messages.sharePassengerCopyLinkButtonTitle
        )}
        buttonClick={handleCopyLink}
        theme={'grey'}
      />
    </ListingRow>
  );
};

export default PassengerShareItem;
