// React specific
import React, { useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useIntl } from 'react-intl';
import { Link } from 'gatsby';

// Components
import SubTitleWithIcon from '../../PersonalInfo/SubTitleWithIcon';
import Card from '../../../components/Card';
import FlightAirportInformationStripe from '../../FlightAirportInformationStripe';
import FlightAirportInformationStripeMobileVersion from '../../FlightAirportInformationStripeMobileVersion';

// Translation
import claimOverviewMessages from './claimOverviewMessages';
import { format } from 'date-fns';
import routes from '../../../messages/routes';
import { translateRoute } from '../../../utils/routes';
import getTranslatedClaimFlightStatus from '../../../services/resources/claimGetters/filterFlightStatus';
import getClaimFlightsSchedule from '../../../services/resources/claimGetters/filterFlightRoute';

// Models
import { ValidationFlightModel } from '../../../models/ValidationFlightModel';
import { CustomerFlightModel } from '../../../models/CustomerFlightModel';

const ClaimListItem = (props: { claimData: {} }) => {
  const { formatMessage } = useIntl();

  const [flightsData] = useState<
    CustomerFlightModel[] | ValidationFlightModel[]
  >(getClaimFlightsSchedule(props?.claimData));

  const claimStateTitle = props.claimData?.currentState?.title;

  const claimLink = useMemo(() => {
    return translateRoute([
      routes.account,
      routes.claim,
      props.claimData?.claim_number,
    ]);
  }, [props.claimData?.claim_number]);

  return (
    <div className="mt-30 text-xl">
      <Card
        style={{
          padding: '1.875rem',
        }}
      >
        <SubTitleWithIcon
          as="div"
          className="border-b pb-5 border-gray-200 flex flex-col"
          icon={null}
        >
          <div className="flex min-w-full flex-row justify-center sm:justify-between">
            <p className="text-lg font-bold mt-0">
              {props?.claimData
                ? formatMessage(getTranslatedClaimFlightStatus(props.claimData))
                : null}
            </p>
            <p
              className={'m-0 font-light hidden sm:inline text-lg'}
              dangerouslySetInnerHTML={{
                __html: claimStateTitle,
              }}
            />
          </div>
          <div className="flex min-w-full flex-row justify-center sm:justify-start">
            <p className="mt-0 text-root font-light">
              {formatMessage(claimOverviewMessages.claimNumber)}
              {props.claimData?.claim_number}
            </p>
          </div>
        </SubTitleWithIcon>
        <div className="flex flex-col sm:flex-row justify-between items-center">
          <div className="flex flex-col">
            {!isMobile ? (
              <FlightAirportInformationStripe
                style={{
                  fontSize: '20px',
                }}
                flights={flightsData}
                borderBottom={false}
              />
            ) : (
              <FlightAirportInformationStripeMobileVersion
                customerFlights={flightsData}
                showAirportNames={false}
                showCityNames={false}
              />
            )}
            {flightsData?.[0]?.flight_date ||
            flightsData?.[0]?.scheduled_gate_departure_date ? (
              <p className="text-root mt-0">
                {format(
                  new Date(
                    flightsData?.[0]?.flight_date ||
                      flightsData?.[0]?.scheduled_gate_departure_date
                  ),
                  'd MMMM yyyy'
                )}{' '}
                / {flightsData?.[0]?.airline?.name}
              </p>
            ) : null}
          </div>
          <Link
            className={
              'button button-small button-secondary mt-30 sm:m-0 text-primary-button-text bg-primary-400 border-primary-400 hover:text-primary-400 hover:bg-white'
            }
            to={claimLink}
          >
            {formatMessage(claimOverviewMessages.goToClaim)}
          </Link>
        </div>
      </Card>
    </div>
  );
};

export default ClaimListItem;
