import { jsonApiAxios } from '../api';

// firstname
// lastname
// birthday
// streetname
// country_id
// phone
// city
// postal
// email
// site_id
// language_id

export const accountsSchema = {
  attributes: [
    'email',
    'firstname',
    'lastname',
    'birthday',
    'streetname',
    'postal',
    'city',
    'phone',
    'country',
  ],
  country: {
    ref: 'id',
  },
};

export const accountPasswordSchema = {
  attributes: ['current_password', 'password'],
};

export function updateAccount(id, values) {
  return jsonApiAxios.patch(`/accounts/${id}`, values, {
    schema: accountsSchema,
    type: 'customers',
  });
}

export function updatePassword(id, values) {
  return jsonApiAxios.post(`accounts/${id}/password`, values, {
    schema: accountPasswordSchema,
    type: 'customers',
  });
}
