// React specific
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

// Formik
import { Form, Formik } from 'formik';

// Components
import CollapsibleContainer from '../../../../components/CollapsibleContainer';
import PassengerDataForm from '../../../../components/PassengerDataForm';
import Button from '../../../../components/Button';
import SubTitleWithIcon from '../../../../views/PersonalInfo/SubTitleWithIcon';
import Alert from '../../../../components/Alert.js';
import { useModal } from '../../../../components/Modals/ModalContext';
// Icons
import userIcon from '../../../../assets/icons/user-icon.inline.svg';
import { validateForm } from '../../../Passengers/PassengerForm';

// Translations
import claimPassengerListMessages from './claimPassengerListMessages';

// Utils
import useSortFields from '../../../../hooks/useSortFields';
import { PassengerModel } from '../../../../models/PassengerModel';
import clsx from 'clsx';
import { PersonModel } from '../../../../models/PersonModel';
import {
  createPassenger,
  // updatePassenger,
  deletePassenger,
} from '../../../../services/resources/passengers';
import {
  updatePerson,
  createPerson,
} from '../../../../services/resources/persons';

import { useBuild } from '../../../../services/GlobalStore/GlobalStore';

// Error handling
import { useHandleError } from '../../../../services/api';

const defaultRequiredFields = [
  'first_name',
  'last_name',
  'date_of_birth',
  'phone',
];

const ClaimPassengerListItem = (props: {
  passengerId?: string;
  handleRemoveNewPassenger?: () => void;
  claimId: {};
  reloadPassengers: () => void;
  readonly: boolean;
  onlyOnePassenger: boolean;
}): JSX.Element => {
  const { formatMessage } = useIntl();
  const {
    passengerId,
    handleRemoveNewPassenger,
    claimId,
    reloadPassengers,
    readonly,
    onlyOnePassenger,
  } = props;
  const [showModal] = useModal('default');
  const [handleError] = useHandleError();
  const [showAlert, setShowAlert] = useState(false);

  const passenger: PassengerModel = useBuild('passengers', passengerId);

  function handleRemovePassenger(passengerToRemove: PassengerModel) {
    showModal({
      title: formatMessage(claimPassengerListMessages.modalTitle),
      onCancelText: formatMessage(claimPassengerListMessages.cancelButton),
      onConfirmText: formatMessage(claimPassengerListMessages.removeButton),
      onConfirm: async () => {
        try {
          if (passengerId) {
            await deletePassenger({ passengerId: passengerToRemove.id });
            reloadPassengers();
          } else {
            handleRemoveNewPassenger();
          }
        } catch (error) {
          handleError(error);
        }
      },
    });
  }

  async function handleSavePassenger(
    values,
    {
      setSubmitting,
      // resetForm
    }
  ) {
    setShowAlert(false);
    if (values.id) {
      try {
        await updatePerson({
          personId: values.id,
          values: {
            ...values,
            country: { id: values?.country_id },
            countryOfBirth: { id: values?.country_of_birth_id },
          },
        }).then(() => {
          setShowAlert(true);
        });
        reloadPassengers();
        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
        handleError(error);
      }
    } else if (values) {
      try {
        const { id: personId } = await createPerson({
          ...values,
        });

        await createPassenger({
          claim: { id: claimId },
          person: { id: personId },
        }).then(() => {
          setShowAlert(true);
        });

        setSubmitting(false);
        reloadPassengers();
        handleRemoveNewPassenger();
      } catch (error) {
        setSubmitting(false);
        handleError(error);
      }
    }
  }

  const person: PersonModel = passenger.person;

  // todo: replace this with useSortFields hook
  const filterSignatureField = (fields = []) => {
    return fields.filter(item => item !== 'signature');
  };

  return (
    <CollapsibleContainer
      key={passenger?.id}
      icon={userIcon}
      subTitle={
        person
          ? !person.is_minor
            ? `${person.first_name} ${person.last_name}`
            : formatMessage(claimPassengerListMessages.minorTitle, {
                // eslint-disable-next-line
                cta: (...chunks) => (
                  <span>
                    {person.first_name} {person.last_name}{' '}
                    <span className="m-0 p-0 font-normal">{chunks}</span>
                  </span>
                ),
              })
          : formatMessage(claimPassengerListMessages.newPassengerTitle)
      }
      // eslint-disable-next-line
      handleRemove={
        readonly || onlyOnePassenger
          ? undefined
          : () => handleRemovePassenger(passenger)
      }
      initalCollapsed={handleRemoveNewPassenger ? true : false}
      className={'mr-8 ml-8 p-30 bg-white mt-30'}
    >
      <div className={'sm:m-30'}>
        <Formik
          key={person?.id}
          onSubmit={handleSavePassenger}
          validateOnMount={true}
          enableReinitialize={true}
          initialValues={{
            ...person,
            country_id: person?.country?.id,
            country_of_birth_id: person?.countryOfBirth?.id,
          }}
          isInitialValid={false}
          // eslint-disable-next-line
          validate={values =>
            validateForm(values, values?.person?.required_fields)
          }
        >
          {({ isSubmitting, isValid, dirty }) => (
            <Form>
              <SinglePassenger
                requiredFields={
                  passengerId ? person.required_fields : defaultRequiredFields
                }
                alertComponent={
                  showAlert && !dirty ? (
                    <Alert className={'mb-30'} level="success">
                      <p className={'p-0 m-0'}>
                        {formatMessage(
                          claimPassengerListMessages.successMessage
                        )}
                      </p>
                    </Alert>
                  ) : null
                }
                readonly={readonly}
              />
              {readonly ? null : (
                <>
                  <Button
                    theme="primary"
                    type="submit"
                    showIcon={false}
                    className={clsx(
                      'w-full sm:max-w-140 p-20 self-center w-auto',
                      {
                        'mb-20': person?.is_minor,
                      }
                    )}
                    disabled={!isValid || isSubmitting || !dirty}
                    size="small"
                    isSubmitting={isSubmitting}
                  >
                    {formatMessage(
                      claimPassengerListMessages.claimPassengerSaveButton
                    )}
                  </Button>

                  <Button
                    theme="secondary"
                    type="button"
                    showIcon={false}
                    className={clsx(
                      'mt-30 w-full sm:max-w-140 p-20 m-0 self-center w-auto sm:hidden',
                      {
                        'mb-20': person?.is_minor,
                      }
                    )}
                    size="small"
                    // eslint-disable-next-line
                    onClick={
                      readonly || onlyOnePassenger
                        ? undefined
                        : () => handleRemovePassenger(passenger)
                    }
                  >
                    {formatMessage(claimPassengerListMessages.removeButton)}
                  </Button>
                </>
              )}
            </Form>
          )}
        </Formik>
        {person?.legalGuardians?.map((legalGuardian: PersonModel) => (
          <Formik
            key={legalGuardian?.id}
            onSubmit={handleSavePassenger}
            validateOnMount={true}
            initialValues={{
              ...legalGuardian,
              country_of_birth_id: legalGuardian?.countryOfBirth?.id,
              country_id: legalGuardian?.country?.id,
            }}
            isInitialValid={false}
            // eslint-disable-next-line
            validate={values =>
              validateForm(
                values,
                filterSignatureField(legalGuardian?.required_fields)
              )
            }
          >
            {({ isSubmitting, isValid, values }) => (
              <Form key={values.id}>
                <hr className="border-gray-200 mb-30" />
                <SubTitleWithIcon
                  icon={userIcon}
                  iconClassName={'mr-18'}
                  className="fill-primary text-lg"
                >
                  {formatMessage(
                    claimPassengerListMessages.legalGuardianTitle,
                    {
                      // eslint-disable-next-line
                      cta: (...chunks) => (
                        <span>
                          {values?.first_name} {values?.last_name}{' '}
                          <span className="m-0 p-0 font-normal">{chunks}</span>
                        </span>
                      ),
                    }
                  )}
                </SubTitleWithIcon>
                <SinglePassenger
                  key={legalGuardian.id}
                  requiredFields={legalGuardian?.required_fields}
                  readonly={readonly}
                />
                {readonly ? null : (
                  <Button
                    theme="primary"
                    type="submit"
                    showIcon={false}
                    className={
                      'w-full sm:max-w-140 p-20 mb-20 self-center w-auto'
                    }
                    disabled={!isValid || isSubmitting}
                    size="small"
                    isSubmitting={isSubmitting}
                  >
                    {formatMessage(
                      claimPassengerListMessages.claimPassengerSaveButton
                    )}
                  </Button>
                )}
              </Form>
            )}
          </Formik>
        ))}
      </div>
    </CollapsibleContainer>
  );
};

// eslint-disable-next-line
const SinglePassenger = (props: {
  requiredFields: string[];
  alertComponent: JSX.Element | null;
  readonly: boolean;
}): JSX.Element => {
  const { chunkedFields } = useSortFields(props.requiredFields, []);
  return (
    <PassengerDataForm
      chunkedFields={chunkedFields}
      alertComponent={props.alertComponent}
      disabled={props.readonly}
    />
  );
};

export default ClaimPassengerListItem;
