import React, { useState, useEffect } from 'react';
import StepTitle from '../../../components/StepTitle';
import { isMobile } from 'react-device-detect';
import questionmarkIcon from '../../../assets/images/account-page/questionmarkIcon.png';
import { useTranslatedNavigate } from '../../../utils/routes';
import { useIntl } from 'react-intl';
import messages from './newMessages';
import translations from '../../../translations/main.json';

const HelpCard = (props: any) => (
  <div
    className={'rounded px-30 py-25 text-sm mb-0 w-full mb-20 lg:mb-0'}
    style={{ backgroundColor: '#F9FAFB', borderRadius: '16px' }}
    {...props}
  />
);

// eslint-disable-next-line react/no-multi-comp
const HelpFooter = ({ searchedText }: { searchedText?: string }) => {
  const { formatMessage } = useIntl();
  const [selectedAnswer, setSelectedAnswer] = useState('');
  const [counter, setCounter] = useState(5);
  const translatedNavigate = useTranslatedNavigate();

  useEffect(() => {
    setSelectedAnswer('');
  }, [searchedText]);

  useEffect(() => {
    if (selectedAnswer === 'no') {
      if (counter > 0) {
        setTimeout(() => setCounter(counter - 1), 1000);
      } else {
        goToContactPage();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, selectedAnswer]);

  const goToContactPage = () => {
    translatedNavigate([
      '/',
      translations['cc4.seo.route.account'],
      translations['cc4.account-page.help.message-contact-us-route'],
    ]);
  };

  return (
    <div style={{ marginTop: 20, width: '100%' }}>
      <HelpCard>
        <div
          style={
            isMobile
              ? {}
              : {
                  display: 'grid',
                  gridGap: '5%',
                  width: '100%',
                  gridTemplateColumns: '5fr 1fr',
                }
          }
        >
          {!searchedText?.length ? (
            <>
              <div>
                <StepTitle
                  style={{
                    marginBottom: 10,
                    marginTop: 0,
                    textAlign: isMobile ? 'left' : 'unset',
                  }}
                  className="text-black lg:text-xxl text-xll"
                >
                  <img
                    src={questionmarkIcon}
                    alt=""
                    style={{
                      float: 'left',
                      marginRight: '10px',
                      marginTop: '-5px',
                    }}
                  />{' '}
                  {formatMessage(messages.helpFooterTitle)}
                </StepTitle>
                <span
                  style={{
                    fontSize: 18,
                    fontWeight: 400,
                    color: '#667085',
                    marginLeft: '6%',
                  }}
                >
                  {formatMessage(messages.helpFooterSubTitle)}
                </span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <button
                  style={{
                    borderRadius: '8px',
                    border: '1px solid',
                    padding: '10px 16px 10px 16px',
                    backgroundColor: '#f36b13',
                    color: 'white',
                  }}
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={goToContactPage}
                >
                  {formatMessage(messages.helpFooterButtonTitle)}
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="flex items-center">
                <span
                  style={{
                    color: '#101828',
                  }}
                  className="font-semibold text-base leading-6"
                >
                  {formatMessage(messages.helpFooterConfirmationButtonTitle)}
                </span>
              </div>
              <div>
                <div
                  style={
                    !isMobile ? { display: 'flex', alignItems: 'center' } : {}
                  }
                >
                  <button
                    style={{
                      borderRadius: '8px',
                      border: '1px solid',
                      padding: '10px 16px 10px 16px',
                      backgroundColor:
                        selectedAnswer === 'yes' ? '#f36b13' : '#667085',
                      color: 'white',
                      outline: 'none',
                    }}
                    disabled={selectedAnswer === 'no'}
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={() => setSelectedAnswer('yes')}
                  >
                    {formatMessage(messages.helpFooterButtonYes)}
                  </button>
                  <button
                    style={{
                      borderRadius: '8px',
                      border: '1px solid',
                      padding: '10px 16px 10px 16px',
                      backgroundColor:
                        selectedAnswer === 'no' ? '#f36b13' : '#667085',
                      color: 'white',
                      outline: 'none',
                    }}
                    disabled={selectedAnswer === 'yes'}
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={() => setSelectedAnswer('no')}
                  >
                    {formatMessage(messages.helpFooterButtonNo)}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
        {selectedAnswer === 'no' ? (
          <div style={{ padding: '10px 0' }}>
            <span
              style={{
                fontSize: '13px',
                fontWeight: 300,
                lineHeight: '24px',
                color: '#101828',
                float: 'right',
                marginTop: isMobile ? '-20px' : '0px',
              }}
            >
              {formatMessage(messages.helpFooterRedirectText, {
                counter: <span>{counter}</span>,
              })}
            </span>
          </div>
        ) : selectedAnswer === 'yes' ? (
          <div style={{ padding: '10px 0' }}>
            <span
              style={{
                fontSize: '13px',
                fontWeight: 300,
                lineHeight: '24px',
                color: '#101828',
                float: 'right',
              }}
            >
              {formatMessage(messages.helpFooterFeedbackText)}
            </span>
          </div>
        ) : null}
      </HelpCard>
    </div>
  );
};

export default HelpFooter;
