import userIconGrey from '../../assets/icons/user-icon-grey.inline.svg';
import routes from '../../messages/routes';
import passwordIcon from '../../assets/icons/password-icon.inline.svg';
import messages from './messages';

const accountSideMenuItems = [
  {
    to: routes.myProfile,
    icon: userIconGrey,
    basepath: routes.account,
    title: messages.personalDetails,
  },
  {
    to: routes.password,
    icon: passwordIcon,
    basepath: routes.account,
    title: messages.changePassword,
  },
];

export default accountSideMenuItems;
