// React specific
import React, { useState } from 'react';

// Styling
import clsx from 'clsx';

// Comonents
import Icon from './Icon';

export interface FiltersDropdownProps {
  icon: string;
  btnTitle: string;
  btnDisabled: boolean;
  fixedOptionsOnTop: { label: string; value: string | null | undefined }[];
  options: { label: string; value: string }[];
  handleOnOptionClicked: (option: {
    label: string;
    value: string | null | undefined;
  }) => void;
}

const FiltersDropdown = (props: FiltersDropdownProps) => {
  const {
    icon,
    btnTitle,
    btnDisabled,
    fixedOptionsOnTop,
    options,
    handleOnOptionClicked,
  } = props;

  const [activeOptionIndex, setActiveOptionIndex] = useState();
  const [activeFixedOptionIndex, setActiveFixedOptionIndex] = useState(0);
  const [dropDownOpen, setDropdownOpen] = useState(false);

  function openDropdownDecision() {
    return !dropDownOpen ? setDropdownOpen(true) : setDropdownOpen(false);
  }

  return (
    <div className={'flex flex-col items-end'}>
      <button
        type="button"
        onClick={openDropdownDecision}
        className={clsx('flex', {
          'opacity-50 cursor-not-allowed pointer-events-none': btnDisabled,
        })}
      >
        <div className={'flex items-center'}>
          <Icon className={'w-15 h-15'} src={icon} />
          <span className={'flex ml-10 font-bold'}>{btnTitle}</span>
        </div>
      </button>
      {dropDownOpen ? (
        <div
          className={
            'flex flex-col absolute items-start justify-center mt-30 p-15 shadow-sm rounded bg-white'
          }
        >
          {fixedOptionsOnTop.map(
            (
              fixedOption: { label: string; value: string | null | undefined },
              index: number
            ) => {
              return (
                <li
                  key={`name-${index}`}
                  className={clsx('list-none', {
                    ['font-bold']: index === activeFixedOptionIndex,
                  })}
                >
                  <a
                    className={'no-underline text-gray-400 text-md'}
                    onClick={() => {
                      setActiveOptionIndex(undefined);
                      setActiveFixedOptionIndex(index);
                      handleOnOptionClicked(fixedOption);
                    }}
                  >
                    {fixedOption.label}
                  </a>
                </li>
              );
            }
          )}
          {options.map(
            (option: { label: string; value: string }, index: number) => {
              return (
                <li
                  key={option.value}
                  className={clsx('list-none', {
                    ['font-bold']: index === activeOptionIndex,
                  })}
                >
                  <a
                    className={'no-underline text-gray-400 text-md'}
                    onClick={() => {
                      setActiveFixedOptionIndex(undefined);
                      setActiveOptionIndex(index);
                      handleOnOptionClicked(option);
                    }}
                  >
                    {option.label}
                  </a>
                </li>
              );
            }
          )}
        </div>
      ) : null}
    </div>
  );
};

export default FiltersDropdown;
