import React from 'react';
import { useIntl } from 'react-intl';

import StepTitle from '../../../../../components/StepTitle';
import { Listing, ListingRow } from '../../../../../components/Listing';
import UploadedDocumentItem from './UploadedDocumentItem';

import {
  useJsonApi,
  useBuild,
} from '../../../../../services/GlobalStore/GlobalStore';

import messages from '../messages';

import { DossierItemModel } from '../../../../../models/DossierItemModel';

const UploadedDocuments = ({ claimId }: { claimId: string }) => {
  const { formatMessage } = useIntl();

  const { data, get } = useJsonApi(
    `claims/${claimId}/dossierItems`,
    {
      credentials: 'include',
      cachePolicy: 'no-cache',
      queryParams: {
        include: ['file', 'category', 'passengers.person'].join(),
        filter: {
          'category.id': {
            nin: ['11', '101'].join(),
          },
        },
      },
    },
    []
  );

  const claim = useBuild('claims', claimId);

  if (!data?.length) return null;

  return (
    <>
      <StepTitle className="mb-30">
        {formatMessage(messages.uploadedDocumentsTitle)}
      </StepTitle>
      <Listing>
        <ListingRow className="font-bold font-pt-sans hidden sm:flex">
          <div
            style={{
              flexBasis: '35%',
            }}
          >
            {formatMessage(messages.passengerColumnTitle)}
          </div>
          <div
            style={{
              flexBasis: '20%',
            }}
          >
            {formatMessage(messages.typeColumnTitle)}
          </div>
          <div
            style={{
              flexBasis: '15%',
            }}
          >
            {formatMessage(messages.statusColumnTitle)}
          </div>
          <div
            style={{
              flexBasis: '25%',
            }}
          >
            {formatMessage(messages.filenameColumnTitle)}
          </div>
          <div
            style={{
              flexBasis: '5%',
            }}
          />
        </ListingRow>
        {data?.map?.((dossierItem: DossierItemModel) => (
          <UploadedDocumentItem
            key={`dossier-${dossierItem?.id}`}
            dossierItem={dossierItem}
            reloadDocuments={get}
            claimApproved={claim?.approved}
          />
        ))}
      </Listing>
    </>
  );
};

export default UploadedDocuments;
