import React, { useEffect } from 'react';
// Components
import AccountContainer from '../../../components/AccountContainer';
import Card from '../../../components/Card';
import HelpFooter from './HelpFooter';
import HelpContent from './HelpContent';
import CommonQuestions from './CommonQuestions';
import { useJsonApi } from '../../../services/GlobalStore/GlobalStore';

const DIVIDER_STYLE = {
  marginTop: '5%',
  borderBottom: '1px solid #d9dbe9',
};

const Help = () => {
  const { data } = useJsonApi(
    '/faqCategories',
    {
      credentials: 'include',
      cachePolicy: 'no-cache',
      queryParams: {
        include: 'faqs',
      },
    },
    []
  );

  useEffect(() => {
    const chatbot = document.getElementById('chatbot');
    if (chatbot && chatbot.className) {
      chatbot.className = chatbot.className.replace('hidden', 'block');
      return () => {
        chatbot.className = chatbot?.className.replace('block', 'hidden');
      };
    }
  }, []);

  return (
    <AccountContainer hasSideBarComponent={false}>
      <Card>
        {data ? <HelpContent faqCategories={data} /> : null}
        <div style={DIVIDER_STYLE} />
        <CommonQuestions questions={data} />
        <div style={DIVIDER_STYLE} />
        <HelpFooter />
      </Card>
    </AccountContainer>
  );
};

export default Help;
