import React from 'react';

import { useBuild } from '../../../../services/GlobalStore/GlobalStore';

import { getIdFromNumber } from '../../../ClaimPage/Claim';

import AccountContainer from '../../../../components/AccountContainer';
import claimDetailsSideMenuItems from '../claimDetailsSideMenuItems';

import claimDetailsMessages from '../claimDetailsMessages';
import backIcon from '../../../../assets/icons/back-arrow-icon.inline.svg';
import { useTranslatedNavigate } from '../../../../utils/routes';
import { PageType } from '../../../Passengers/PassengerContainer';

/**
 * This component is used to determine what document page should be displayed
 * Basing on the URL it displays:
 *  - document upload page with doc type param if passenger and type of doc
 *  or: - sign document page
 *  or: - upload additional cos doc page
 */
const DocumentSubpage = (component: React.FC) => (props: {
  claimNumber: string;
  passengerId: string;
}) => {
  const claimId = getIdFromNumber(props.claimNumber);
  const Component = component;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const translatedNavigate = useTranslatedNavigate();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const claim = useBuild('claims', claimId);

  function navigateNext() {
    const path = claimDetailsSideMenuItems(claim?.claim_number)[1].to;
    translatedNavigate(path);
  }

  return (
    <AccountContainer
      menuItems={[
        {
          ...claimDetailsSideMenuItems(claim?.claim_number)[2],
          title: claimDetailsMessages.sideMenuItemBack,
          icon: backIcon,
        },
      ]}
    >
      {/* @ts-ignore */}
      <Component
        {...props}
        claimId={claimId}
        navigateNext={navigateNext}
        pageType={PageType.Account}
      />
    </AccountContainer>
  );
};

export default DocumentSubpage;
