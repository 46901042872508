import React from 'react';
import PropTypes from 'prop-types';

import routes from '../messages/routes';
import useAuthenticate from '../hooks/useAuthenticate';
import { isBrowser } from '../utils/utils';
import { Redirect, useLocation } from '@reach/router';
import { translateRoute } from '../utils/routes';
import { parse } from 'qs';

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { isLoggedIn, doLogin } = useAuthenticate();

  const { pathname } = useLocation();

  const loginRoute = '/' + translateRoute(routes.login);

  const impersonateCustomer = parse(location?.search.slice(1))
    ?.impersonateCustomer;

  const claimsOverviewRoute =
    '/' +
    translateRoute(routes.account) +
    '/' +
    translateRoute(routes.claimsOverview);

  if (
    impersonateCustomer === '1' &&
    location?.pathname === claimsOverviewRoute
  ) {
    doLogin();
  }

  if (
    isBrowser() &&
    !isLoggedIn &&
    !(location?.pathname).includes(loginRoute)
  ) {
    return (
      <Redirect
        to={loginRoute + (pathname ? `?redirect=${pathname}` : '')}
        noThrow
      />
    );
  }

  return <Component location={location} {...rest} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.object,
  location: PropTypes.object,
};

export default PrivateRoute;
