import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'cc4.form.ticket-number.header-text',
    defaultMessage: 'Enter Boarding Pass/E-ticket Number',
  },
  explanation: {
    id: 'cc4.form.ticket-number.explanation.text',
    defaultMessage:
      'You can find your ticket number (consisting of 13 numbers) on your boarding pass and/or E-ticket. Every passenger has a unique ticket number per flight ticket.',
  },
  ticketNmrLabel: {
    id: 'cc4.form.ticket-number.label.number',
    defaultMessage: 'Ticket number',
  },
  ticketNmrPlaceholder: {
    id: 'cc4.form.ticket-number.label.number-placeholder',
    defaultMessage: 'e.g. 123987654321',
  },
  imageAlternative: {
    id: 'cc4.form.ticket-number.image-alternative',
    defaultMessage: 'Ticket number',
  },
  updateButtonText: {
    id: 'cc4.form.ticket-number.update',
    defaultMessage: 'Update',
  },
  successMessage: {
    id: 'cc4.form.ticket-number.success',
    defaultMessage: 'Ticket number of passenger(s) were updated succesfully!',
  },
  dangerMessage: {
    id: 'cc4.form.ticket-number.danger',
    defaultMessage: 'Something went wrong please try again!',
  },
  validationMsg: {
    id: 'cc4.form.ticket-number.validation',
    defaultMessage: 'Characters must be numbers',
  },
  mainBookerLabel: {
    id: 'cc4.form.ticket-number.main_booker',
    defaultMessage: 'main booker',
  },
  minorLabel: {
    id: 'cc4.form.ticket-number.minor',
    defaultMessage: 'minor',
  },
});
