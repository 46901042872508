import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import ReactDOM from 'react-dom';
import { isMobile } from 'react-device-detect';
import FlightAirportInformationStripe from '../../FlightAirportInformationStripe';
import FlightAirportInformationStripeMobileVersion from '../../FlightAirportInformationStripeMobileVersion';

// Translations
import claimDetailsMessages from './claimDetailsMessages';

export default function ClaimDetailsSubheader({
  claimNumber,
  flightStatus,
  customerFlights,
}) {
  const { formatMessage } = useIntl();

  const subheader = document.getElementById('subheader');
  const wrapper = document.createElement('div');

  useEffect(() => {
    subheader?.appendChild(wrapper);
    return () => subheader?.removeChild(wrapper);
  }, [subheader, wrapper]);

  return ReactDOM.createPortal(
    <div
      id={'claimDetailsFlightInformationHeader'}
      className={
        'max-w-1180 w-full mx-auto flex mx-0 h-70 flex-row justify-between items-center'
      }
    >
      {!isMobile ? (
        <FlightAirportInformationStripe
          style={{
            marginTop: 20,
            fontSize: 20,
          }}
          flights={customerFlights}
          borderBottom={false}
        />
      ) : (
        <FlightAirportInformationStripeMobileVersion
          customerFlights={customerFlights}
        />
      )}
      <p className="mt-0 hidden sm:inline">
        {formatMessage(claimDetailsMessages.claimTitleInHeader, {
          flightStatus: <b>{flightStatus}</b>,
          claimNumber,
        })}
      </p>
    </div>,
    wrapper
  );
}
