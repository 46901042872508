// React specific
import React from 'react';

// Icons
import PlaneIconSVG from '../assets/icons/plane-icon.inline.svg';

// Models
import { CustomerFlightModel } from '../models/CustomerFlightModel';

const planeIcon: JSX.Element = (
  <PlaneIconSVG className="mx-8 inline-block w-30 h-auto" />
);

/**
 *
 * This component dynamically returns a h2 airport stripe depending on the airports
 * @param customerFlights
 * @returns JSX.Element
 *
 */
const FlightAirportInformationStripeMobileVersion = ({
  customerFlights,
  showAirportNames = true,
  showCityNames = true,
}: {
  customerFlights: CustomerFlightModel[];
  showAirportNames?: boolean;
  showCityNames?: boolean;
}): JSX.Element => {
  const destinationAirport =
    customerFlights?.[customerFlights?.length - 1]?.arrivalAirport;
  const startAirport = customerFlights?.[0]?.departureAirport;
  return (
    <div
      className={'flex min-w-full flex-row items-center justify-between p-20'}
    >
      <div>
        <h3>{startAirport?.iata}</h3>
        <p className={'m-0'}>
          {(showCityNames && startAirport?.city?.name) ||
            (showAirportNames && startAirport?.name)}
        </p>
      </div>
      {planeIcon}
      <div>
        <h3>{destinationAirport?.iata}</h3>
        <p className={'m-0'}>
          {(showCityNames && destinationAirport?.city?.name) ||
            (showAirportNames && destinationAirport?.name)}
        </p>
      </div>
    </div>
  );
};

export default FlightAirportInformationStripeMobileVersion;
