// React specific
import React, { useEffect } from 'react';

// Backend services
import { useJsonApi } from '../../../services/GlobalStore/GlobalStore';

// Components
import Loading from '../../../components/Loading';
import ClaimList from './ClaimList';
import AccountContainer from '../../../components/AccountContainer';
import claimOverviewMessages from './claimOverviewMessages';
import { useIntl } from 'react-intl';

/**
 * Component shows all claims for one user
 */
const ClaimsOverview = () => {
  const { formatMessage } = useIntl();
  const { data: claims, get: getClaims } = useJsonApi(`/claims`, {
    cachePolicy: 'no-cache',
    queryParams: {
      include: [
        'customer',
        'currentState',
        'placeOfJurisdiction',
        'customerFlights.airline',
        'customerFlights.departureAirport',
        'customerFlights.departureAirport.city',
        'customerFlights.arrivalAirport',
        'customerFlights.arrivalAirport.city',
        'flights.airline',
        'flights.departureAirport',
        'flights.departureAirport.city',
        'flights.arrivalAirport',
        'flights.arrivalAirport.city',
      ].join(),
      sort: '-created_at',
    },
  });

  useEffect(() => {
    if (!claims) getClaims();
  }, [claims, getClaims]);

  return !claims ? (
    <div className="flex justify-center">
      <Loading />
    </div>
  ) : (
    <AccountContainer>
      <ClaimList claims={claims} />
      <div className={'flex w-full justify-center items-center py-50'}>
        <h2 className={'text-xl text-center text-gray-400'}>
          {formatMessage(claimOverviewMessages.moreDisruptedFlights)}
        </h2>
      </div>
    </AccountContainer>
  );
};

export default ClaimsOverview;
