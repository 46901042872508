import React from 'react';
import { Location } from '@reach/router';
import Layout from './layout';
import { StateProvider } from '../services/GlobalStore/GlobalStore';
import TranslatedRouter from './TranslatedRouter';
import LayoutHeader from './LayoutHeader';

const subHeaderWrapper = React.createElement('div', {
  id: 'subheader',
  className: 'shadow-menu-mobile sm:shadow-menu bg-white mt-1',
});

const AccountRouter = ({ children, ...props }: { children: any }) => {
  return (
    <Layout showStepper={false}>
      <StateProvider>
        <Location>
          {({ location }) => (
            <>
              <LayoutHeader showStepper={true} location={location} />
              {subHeaderWrapper}
              <TranslatedRouter
                location={location}
                className="max-w-1180 w-full mx-auto mb-30"
                {...props}
              >
                {children}
              </TranslatedRouter>
            </>
          )}
        </Location>
      </StateProvider>
    </Layout>
  );
};

export default AccountRouter;
