import React, { useState } from 'react';
import SearchedQuestions from './SearchedQuestions';
import { flatMap } from 'lodash';
import { isMobile } from 'react-device-detect';
import SearcIcon from '../../../../assets/icons/search-question-icon.svg';
import messages from '../newMessages';
import { useIntl } from 'react-intl';
import translations from '../../../../translations/main.json';
import { useTranslatedNavigate } from '../../../../utils/routes';

const HelpSearchBar = ({ faqCategories }: { faqCategories: any[] }) => {
  const [searchValue, setSearchValue] = useState('');
  const [hasSelectedValue, hasSetSelectedValue] = useState(false);
  const allQuestions = flatMap(faqCategories, 'faqs');
  const width = isMobile ? { width: '100%' } : { width: '50%' };
  const { formatMessage } = useIntl();
  const translatedNavigate = useTranslatedNavigate();

  const handleOnSearch = (e: { target: { value: any } }) => {
    hasSetSelectedValue(false);
    if (e?.target?.value?.length > 2) {
      setSearchValue(e?.target?.value);
    } else if (!e?.target?.value?.length) {
      setSearchValue('');
    }
  };

  const searchQuestions = () => {
    return allQuestions.filter(
      item =>
        item?.question?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
        item?.answer?.toLowerCase()?.includes(searchValue?.toLowerCase())
    );
  };

  const handleSearchResults = () => {
    translatedNavigate(
      '/' +
        translations['cc4.seo.route.account'] +
        '/' +
        translations['cc4.seo.route.help-center'] +
        '/' +
        translations['cc4.account-page.help.message-all-answers-route'],
      {
        state: {
          question: searchQuestions(),
          searchedText: searchValue,
        },
      }
    );
  };

  return (
    <>
      <div className="search-bar-class mt-10 relative" style={width}>
        <input
          type="search"
          placeholder={formatMessage(messages.searchPlaceholder)}
          style={inputStyles}
          // eslint-disable-next-line react/jsx-no-bind
          onChange={handleOnSearch}
        />
        <button
          className="absolute right-0"
          style={buttonStyles}
          // eslint-disable-next-line react/jsx-no-bind
          onClick={handleSearchResults}
        >
          <img src={SearcIcon} alt="" />
        </button>
      </div>
      {searchValue && !hasSelectedValue ? (
        <div className="search-bar-class relative mt-10" style={width}>
          <div className="search-box absolute w-full" style={resultBoxStyles}>
            <div>
              <div>
                <span
                  style={{ fontWeight: 500, fontSize: 14, lineHeight: '28px' }}
                >
                  {formatMessage(messages.resultsPlaceholder)}
                </span>
              </div>
              {faqCategories?.length ? (
                <SearchedQuestions
                  filteredQuestions={searchQuestions()}
                  hasSetSelectedValue={hasSetSelectedValue}
                  allQuestions={allQuestions}
                />
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
      <div className="sub-text mt-10">
        <span
          style={{
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '16.9px',
            color: '#667085',
          }}
        >
          {formatMessage(messages.searchSubText)}
        </span>
      </div>
    </>
  );
};

export default HelpSearchBar;

const inputStyles = {
  width: '100%',
  border: '1px solid',
  borderRadius: '8px',
  padding: '8px 8px 8px 16px',
  height: '54px',
  outlineColor: 'black',
};

const buttonStyles = {
  backgroundColor: '#F36B13',
  height: '70%',
  top: '15%',
  transform: 'translateX(-10%)',
  borderRadius: '8px',
  padding: '10px 16px 10px 16px',
  outlineColor: 'black',
};

const resultBoxStyles = {
  maxHeight: '233px',
  backgroundColor: 'white',
  padding: '10px 16px 10px 16px',
  border: '1px solid #d0d5dd',
  borderRadius: '8px',
  boxShadow: '0px 4px 8px -2px rgba(16, 24, 40, 0.1)',
  overflow: 'auto',
  zIndex: 1,
};
