import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

// Components
import InputGroupWrapper from '../InputGroup';
import Icon from '../../Icon';

import translatable from '../../../utils/propTypes/translatable';

// Icons
import passwordEye from '../../../assets/icons/password-eye-icon.svg';
import passwordEyeCrossed from '../../../assets/icons/password-eye-cross-icon.svg';

const PasswordInputField = ({
  name,
  inputClassName,
  placeholder,
  disabled,
  ...props
}) => {
  const intl = useIntl();
  const [type, setType] = useState('password');

  function setInputType() {
    setType(prevState => (prevState === 'input' ? 'password' : 'input'));
  }

  return (
    <div
      className={clsx(
        'form-input',
        'w-full',
        'sm:max-w-315',
        'placeholder-gray-300',
        'p-0',
        'box-content',
        'inline-flex',
        {
          'form-input-error': props.hasError,
        },
        inputClassName
      )}
    >
      <input
        data-hj-allow
        type={type}
        name={name}
        placeholder={
          placeholder?.id ? intl.formatMessage(placeholder) : placeholder
        }
        disabled={disabled}
        className={clsx(
          'form-input',
          'w-full',
          'sm:max-w-315',
          'placeholder-gray-300',
          {
            'form-input-error': props.hasError,
          },
          'border-none'
        )}
        aria-invalid={props.hasError}
        {...props.field}
      />
      <Icon
        src={type === 'password' ? passwordEye : passwordEyeCrossed}
        className="flex self-center mx-15"
        onClick={setInputType}
      />
    </div>
  );
};

PasswordInputField.defaultProps = {
  type: 'text',
};

PasswordInputField.propTypes = {
  disabled: PropTypes.bool,
  field: PropTypes.object,
  form: PropTypes.object,
  hasError: PropTypes.bool,
  id: PropTypes.string,
  inputClassName: PropTypes.string,
  isTouched: PropTypes.bool,
  label: translatable,
  name: PropTypes.string,
  placeholder: translatable,
  type: PropTypes.string,
};

// eslint-disable-next-line react/no-multi-comp
const PasswordInput = props => (
  <InputGroupWrapper {...props} component={PasswordInputField} />
);

PasswordInput.propTypes = {
  label: translatable,
  name: PropTypes.string.isRequired,
  placeholder: translatable,
};

export default PasswordInput;
