import React, { useState } from 'react';
import AccountContainer from '../../../../components/AccountContainer';
import { useJsonApi } from '../../../../services/GlobalStore/GlobalStore';
import { isMobile } from 'react-device-detect';
import Card from '../../../../components/Card';
import MessagesComponent from './MessagesComponent';
import claudiaLogo from '../../../../assets/images/account-page/Claudia.png';
import { useIntl } from 'react-intl';
import messages from '../newMessages';

const HelpSuppotMessaging = () => {
  const [selectedClaim, setSelectedClaim] = useState({});
  const { formatMessage } = useIntl();
  const { data: claims } = useJsonApi(
    `/claims`,
    {
      cachePolicy: 'no-cache',
      queryParams: {
        include: [
          'customer',
          'currentState',
          'organizations',
          'placeOfJurisdiction',
          'customerFlights.airline',
          'customerFlights.departureAirport',
          'customerFlights.departureAirport.city',
          'customerFlights.arrivalAirport',
          'customerFlights.arrivalAirport.city',
          'unreadSupportCount',
        ].join(),
      },
    },
    []
  );

  return (
    <AccountContainer
      claims={claims}
      setSelectedClaim={setSelectedClaim}
      selectedClaim={selectedClaim}
    >
      {!isMobile ? (
        <Card
          style={
            !selectedClaim?.id
              ? { minHeight: '525px', maxWidth: '856px' }
              : { minHeight: '525px', padding: 0, maxWidth: '856px' }
          }
        >
          {!selectedClaim?.id ? (
            <div className="flex flex-col items-center">
              <div className="relative">
                <img
                  src={claudiaLogo}
                  draggable="false"
                  alt=""
                  style={{
                    objectFit: 'cover',
                    borderRadius: '50%',
                    height: '109.96px',
                    width: '109.96px',
                    border: '6.06px solid #56AA8B',
                    pointerEvents: 'none',
                  }}
                />
                <span className="tooltip-text" style={bubbleText}>
                  {formatMessage(messages.cardIconLabel)}
                </span>
              </div>
              <div className="mt-45 text-center" style={{ width: '50%' }}>
                <span style={headerText}>
                  {formatMessage(messages.cardTitle)}
                </span>{' '}
                <br />
                <span style={textStyle}>
                  {formatMessage(messages.cardText)}
                </span>
              </div>
            </div>
          ) : selectedClaim?.id ? (
            <MessagesComponent claim={selectedClaim} />
          ) : null}
        </Card>
      ) : null}
    </AccountContainer>
  );
};

export default HelpSuppotMessaging;

const bubbleText = {
  minWidth: '180px',
  backgroundColor: 'white',
  color: 'black',
  textAlign: 'center',
  borderRadius: '6px',
  padding: '5px 0',
  position: 'absolute',
  zIndex: 1,
  top: '95%',
  right: '-25%',
  boxShadow: '0px -1px 11px 0px rgba(0, 0, 0, 0.1)',
  transform: 'rotate(5deg)',
};
const headerText = {
  fontWeight: 600,
  fontSize: '26px',
  color: '#101828',
  lineHeight: '31.47px',
  letterSpacing: '-0.02em',
};

const textStyle = {
  fontWeight: 400,
  fontSize: '16px',
  color: '#667085',
  lineHeight: '19.36px',
};
