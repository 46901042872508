// React specific
import clsx from 'clsx';
import React from 'react';

interface SeparatorWithTextProps {
  textClassName?: string;
  separatorClassName: string;
  text: string;
}

const SeparatorWithText = (props: SeparatorWithTextProps): JSX.Element => {
  return (
    <div>
      <h3 className={clsx('separator-text', props.separatorClassName)}>
        <span className={props.textClassName}>{props.text}</span>
      </h3>
    </div>
  );
};

export default SeparatorWithText;
