import { defineMessages } from 'react-intl';

export default defineMessages({
  claimPassengerRemoveTitle: {
    id: 'cc4.claim.passenger-list.remove-title',
    defaultMessage: 'Remove',
  },
  claimPassengerLessTitle: {
    id: 'cc4.claim.passenger-list.less-title',
    defaultMessage: 'Less',
  },
  claimPassengerMoreTitle: {
    id: 'cc4.claim.passenger-list.more-title',
    defaultMessage: 'More',
  },
});
