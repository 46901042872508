import React from 'react';

// Utils
import { isEmpty } from 'lodash';

// Translations
import { useIntl } from 'react-intl';
import messages from './messages';

// Model
import { PassengerModel } from '../../../../models/PassengerModel';

// Props
import { DocsInfoGroupProps } from './DocsInfoGroup';

// Components
import { Listing, ListingRow } from '../../../../components/Listing';
import PassengerShareItem from './PassengerShareItem';

// Services and state
import { useBuild } from '../../../../services/GlobalStore/GlobalStore';

/**
 * Traverses over docsInfoGroups in search for passengers wth requirements.
 * Omits main booker as it's logged user so no share needed for him
 */
const findPassengersWithRequirements = (
  passengers: { [key: string]: PassengerModel },
  docsInfoGroup: DocsInfoGroupProps
) => {
  docsInfoGroup.docsInfoPassengerStatuses.forEach(status => {
    if (!status.passenger.main_booker && status.status !== 'completed') {
      passengers = { ...passengers, [status.passenger.id]: status.passenger };
    }
  });
  return passengers;
};

const PassengerShareUrls = ({
  data,
  claimId,
}: {
  data: DocsInfoGroupProps[];
  claimId: string;
}) => {
  const { formatMessage } = useIntl();
  const passengerShareLinks = data.reduce(findPassengersWithRequirements, {});
  const claim = useBuild('claims', claimId);
  if (isEmpty(passengerShareLinks)) return null;

  return (
    <Listing>
      <ListingRow className="font-bold font-pt-sans">
        <h2 className="text-gray-400 py-30">
          {formatMessage(messages.sharePassengerTitle)}
        </h2>
      </ListingRow>
      {Object.values(passengerShareLinks).map(passenger =>
        passenger ? (
          <div key={passenger.id} data-testid="passengerShareUrl">
            <PassengerShareItem passenger={passenger} claim={claim} />
          </div>
        ) : null
      )}
    </Listing>
  );
};

export default PassengerShareUrls;
