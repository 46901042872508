// React specific
import React from 'react';
import { useIntl } from 'react-intl';

// Components

import Icon from '../../../components/Icon';

// Model
import { PassengerModel } from '../../../models/PassengerModel';
import { ClaimModel } from '../../../models/ClaimModel';

// Translations
import claimDetailsMessages from './claimDetailsMessages';

// Navigation
import routes from '../../../messages/routes';

// Icons
import GreenPen from '../../../assets/icons/green-pen-icon.inline.svg';
import clsx from 'clsx';
import { useTranslatedNavigate } from '../../../utils/routes';

const PassengerSummary = (props: {
  claim: ClaimModel;
  passengers: PassengerModel[];
}) => {
  const { formatMessage } = useIntl();
  const translatedNavigate = useTranslatedNavigate();

  const { claim_number, approved } = props.claim;

  function navigateToClaimPassengerList() {
    translatedNavigate([claim_number, routes.passengersList]);
  }

  return (
    <>
      <div className={'flex justify-between mb-15'}>
        <p className={'font-pt-sans font-bold text-lg text-gray-250 m-0 p-0'}>
          {formatMessage(claimDetailsMessages.passengerTitle)}
        </p>

        {/*  Add the code below after the claimPassengerList is finished */}

        <button
          theme="disabled"
          onClick={navigateToClaimPassengerList}
          className={
            'flex flex-row items-center border-none pl-0 focus:outline-none'
          }
        >
          <p className={'m-0 text-primary-400'}>
            {approved
              ? formatMessage(claimDetailsMessages.moreButtonTitle)
              : formatMessage(claimDetailsMessages.editButtonTitle)}
          </p>
          <GreenPen
            className={clsx('ml-10 fill-primary', {
              ['hidden']: approved,
            })}
            title={`Edit icon`}
          />
        </button>
      </div>
      <hr className={'border-gray-200 mb-30'} />
      <ul className={'list-disc list-inside'}>
        {props?.passengers?.map((passenger: PassengerModel) => (
          <li
            className={
              'text-black font-open-sans font-normal list-style-primary'
            }
            key={passenger?.id}
          >
            {passenger.person.first_name} {passenger.person.last_name}
          </li>
        ))}
      </ul>
    </>
  );
};

export default PassengerSummary;
