/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react';
import SelectTopic from './SelectTopic';
import MessageArea from './MessageArea';
import Messages from './Messages';
import { sendSupportMessage } from '../../../../services/resources/supportMessages';
import { useJsonApi } from '../../../../services/GlobalStore/GlobalStore';
import { useIntl } from 'react-intl';
import Loading from '../../../../components/Loading';
import InfoIcon from '../../../../assets/images/account-page/infoIcon.png';
import newMessages from '../newMessages';
import RecomendedAnswer from './RecomendedAnswer';
import moment from 'moment';
import config from '../../../../config';

const locale = {
  en: 'en-gb',
  nl: 'nl',
  es: 'es',
  pl: 'pl',
  be: 'fr',
  fr: 'fr',
  it: 'it',
  de: 'de',
  da: 'da',
};

/* tslint:disable no-var-requires */
require(`moment/locale/${locale[config?.locale] || 'en-gb'}`);

const MessagesComponent = ({ claim }: { claim: any }) => {
  const [selectedTopic, setSelectedTopic] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { formatMessage } = useIntl();

  const transformDate = (date: string) => {
    const dateFormat = new Date(date);
    const day = dateFormat.getDate();
    const month = moment(dateFormat).format('MMMM');
    const year = dateFormat.getFullYear();

    if (config?.locale.includes('en') || config?.locale.includes('de')) {
      const capitalizeMonth = month[0].toUpperCase() + month.slice(1);
      return `${day} ${capitalizeMonth} ${year}`;
    } else {
      return `${day} ${month} ${year}`;
    }
  };

  const {
    data: messages,
    loading: isLoadingmessages,
    get: loadMessages,
  } = useJsonApi(`/supportMessages`, {
    credentials: 'include',
    cachePolicy: 'no-cache',
  });

  useEffect(() => {
    if (!isLoadingmessages) {
      loadMessages(`?filter[claim.id]=${claim?.id}&include=claim,category`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claim?.id, loadMessages]);

  const sendMessage = () => {
    setIsLoading(true);
    sendSupportMessage(
      {
        message,
        type: 'supportMessages',
        claim: { id: claim?.id },
        category: { id: selectedTopic },
      },
      {}
    )
      .then(async () => {
        const loadedMessages = await loadMessages(
          `?filter[claim.id]=${claim?.id}&include=claim,category`
        );
        if (loadedMessages?.data?.length) {
          setMessage('');
          setIsLoading(false);
          const textArea = document.getElementById('message-textarea');
          if (textArea && textArea.value) {
            textArea.value = '';
          }
        }
      })
      .catch(() => {
        setMessage('');
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="flex items-center justify-between" style={headerStyle}>
        <div className="flex">
          <span className="font-semibold" style={textStyle}>
            {claim?.customerFlights?.length &&
              claim.customerFlights[0]?.airline?.name}{' '}
            -{' '}
            {claim?.customerFlights?.length &&
              transformDate(claim?.customerFlights[0]?.flight_date)}{' '}
          </span>
          <div className="info-tooltip">
            <img src={InfoIcon} alt="" style={{ marginLeft: '15px' }} />
            <div className="info-tooltiptext flex flex-col">
              <span
                style={{
                  fontSize: '12px',
                  fontWeight: 700,
                  lineHeight: '18px',
                  color: '#FFFFFF',
                }}
              >
                {formatMessage(newMessages.toolTipText)}
              </span>
              <span
                style={{ fontSize: '12px', fontWeight: 400, color: '#FFFFFF' }}
              >
                {formatMessage(newMessages.toolTipTextFlight)}:{' '}
                {claim?.customerFlights?.length &&
                  transformDate(claim?.customerFlights[0]?.flight_date)}
              </span>
              <span
                style={{ fontSize: '12px', fontWeight: 400, color: '#FFFFFF' }}
              >
                {formatMessage(newMessages.toolTipTextClaim)}:{' '}
                {claim?.customerFlights?.length &&
                  transformDate(claim?.created_at)}
              </span>
            </div>
          </div>
        </div>
        <span className="font-normal" style={textStyle}>
          {formatMessage(newMessages.messageSupportClaimNr)}:{' '}
          {claim.claim_number}
        </span>
      </div>
      <div className="mt-25" style={{ padding: '0px 32px' }}>
        {!messages?.length ? (
          <>
            <span style={headerTextStyle}>
              {formatMessage(newMessages.helpFooterButtonTitle)}
            </span>{' '}
            <br />
          </>
        ) : (
          <>
            <span style={headerTextStyle}>
              {formatMessage(newMessages.messageSupportTitle)}{' '}
              {claim?.flights?.[0]?.airline_code}
              {claim?.flights?.[0]?.flight_number}
            </span>{' '}
            <br />
          </>
        )}
        <span style={headerSubTextStyle}>
          {formatMessage(newMessages.messageSupportSubTitle)}
        </span>
        <div style={DIVIDER_STYLE} />
      </div>
      {messages?.length && !isLoading && !isLoadingmessages ? (
        <Messages messages={messages} />
      ) : isLoading || isLoadingmessages ? (
        <Loading className="flex justify-center my-30" />
      ) : null}
      {!messages?.length ? (
        <>
          <SelectTopic
            claimId={claim?.id}
            setSelectedTopic={setSelectedTopic}
            hasMessages={!!messages?.length}
            isMobile={false}
          />
          {selectedTopic ? (
            <RecomendedAnswer
              selectedTopic={selectedTopic}
              claimNumber={claim?.claim_number}
              compensationPerPassenger={claim?.compensation_per_passenger}
              withBackground={true}
              currentState={claim?.currentState}
            />
          ) : null}
        </>
      ) : null}
      <MessageArea
        setMessage={setMessage}
        hasMessages={!!messages?.length}
        setSelectedTopic={setSelectedTopic}
        selectedTopic={selectedTopic}
        claimNumber={claim?.claim_number}
        compensationPerPassenger={claim?.compensation_per_passenger}
        claimCurrentState={claim?.currentState}
      />
      <div className="flex justify-end" style={{ padding: '25px 32px' }}>
        <button style={buttonStyle} onClick={sendMessage}>
          {formatMessage(newMessages.messageSendButton)}
        </button>
      </div>
    </>
  );
};

export default MessagesComponent;

const headerStyle = {
  minWidth: '180px',
  height: '70px',
  padding: '0px 32px',
  backgroundColor: '#F9FAFB',
};

const headerTextStyle = {
  fontSize: '32px',
  lineHeight: '38.73px',
  color: '#101828',
  fontWeight: 600,
  letterSpacing: '-2%',
};

const headerSubTextStyle = {
  fontSize: '20px',
  lineHeight: '30px',
  color: '#667085',
  fontWeight: 400,
};

const textStyle = {
  fontSize: '16px',
  lineHeight: '24px',
  color: '#101828',
};
const DIVIDER_STYLE = {
  marginTop: '5%',
  borderBottom: '1px solid #d9dbe9',
};

const buttonStyle = {
  width: '232px',
  padding: '12px 20px',
  backgroundColor: '#F36B13',
  color: '#FFFFFF',
  border: '1px solid #F36B13',
  borderRadius: '8px',
  boxShadow: '0px 1px 2px 0px #1018280D',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '24px',
};
