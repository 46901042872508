import React, { FunctionComponent } from 'react';

// Translation
import { useIntl } from 'react-intl';
import messages from '../../src/views/Account/ClaimDetails/ClaimDocuments/messages';

// Error handling
import { useHandleError } from '../services/api';

interface NativeShareButtonProps {
  url: string;
  buttonText: string;
}

const NativeShareButton: FunctionComponent<NativeShareButtonProps> = ({
  url,
  buttonText,
}: NativeShareButtonProps) => {
  const { formatMessage } = useIntl();
  const [handleError] = useHandleError();

  async function handleSharing() {
    try {
      await navigator.share({ url });
    } catch (error) {
      handleError(error);
    }
  }

  if (navigator.share !== undefined) {
    return (
      <button
        onClick={handleSharing}
        className={
          'outline-none focus:outline-none flex justify-center flex-none w-full text-xs rounded-full bg-primary-400 text-white p-5 focus:outline-none no-underline'
        }
      >
        {buttonText}
      </button>
    );
  } else {
    return (
      <p>{formatMessage(messages.sharePassengerShareFunctionNotSupported)}</p>
    );
  }
};

export default NativeShareButton;
