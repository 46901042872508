/**
 * Helper function for implementing the logic of the flight routes
 * @param claimData ({} | [])
 * @param flight type (optional) - used to display Alternative flights in Flight Itinerary screen
 * @returns Validation flights sorted by type and departure date if the claim has been validated; otherwise returns the Customer flights
 */

// Models
import { CustomerFlightModel } from '../../../models/CustomerFlightModel';
import { ValidationFlightModel } from '../../../models/ValidationFlightModel';

enum claimState {
  SUBMITTED = 'Submitted',
  MORE_INFO_NEEDED = 'FlightValidationMoreInfoNeeded',
}

export type FlightModel = CustomerFlightModel | ValidationFlightModel;

const sortFlightsByDate = (a: FlightModel, b: FlightModel): number => {
  const flightDate_a =
    (a as ValidationFlightModel).flight?.scheduled_gate_departure_date ??
    (a as CustomerFlightModel).flight_date;
  const flightDate_b =
    (b as ValidationFlightModel).flight?.scheduled_gate_departure_date ??
    (b as CustomerFlightModel).flight_date;

  if (flightDate_a && flightDate_b) {
    if (flightDate_a > flightDate_b) {
      return 1;
    }
    if (flightDate_a < flightDate_b) {
      return -1;
    }
  }
  return 0;
};

const getClaimFlightsSchedule = (
  claimData: any,
  flightType?: string
): CustomerFlightModel[] | ValidationFlightModel[] => {
  if (
    ![claimState.SUBMITTED, claimState.MORE_INFO_NEEDED].includes(
      claimData[0]?.currentState?.value
    )
  ) {
    if (flightType) {
      return claimData[0]?.claimValidationFlights
        ?.filter((flight: ValidationFlightModel) => flight?.type === flightType)
        .sort(sortFlightsByDate);
    } else {
      return claimData?.claimValidationFlights?.filter(
        (flight: ValidationFlightModel) => flight?.type === 'booked'
      );
    }
  } else if (flightType) {
    return claimData[0]?.customerFlights
      ?.filter(({ type }: CustomerFlightModel) => type === flightType)
      .sort(sortFlightsByDate);
  } else {
    return claimData.customerFlights?.filter(
      ({ type }: CustomerFlightModel) => type === 'booked'
    );
  }
};

export default getClaimFlightsSchedule;
