import React from 'react';

import HelpTop from './HelpTop';

const HelpContent = ({
  faqCategories,
  searchedText = '',
}: {
  faqCategories: any[];
  searchedText?: string;
}) => {
  return (
    <div>
      <HelpTop faqCategories={faqCategories} searchedText={searchedText} />
    </div>
  );
};

export default HelpContent;
