// React specific
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { format } from 'date-fns';

// Components
import Card from '../../../components/Card';
import Alert from '../../../components/Alert';
import PassengerSummary from './PassengerSummary';
import AccountContainer from '../../../components/AccountContainer';
import claimDetailsSideMenuItems from './claimDetailsSideMenuItems';
import FlightAirportInformationStripe from '../../FlightAirportInformationStripe';

// Services and state
import { useBuild } from '../../../services/GlobalStore/GlobalStore';

// Translations
import claimDetailsMessages from './claimDetailsMessages';

// Model
import routes from '../../../messages/routes';
import { getIdFromNumber } from '../../ClaimPage/Claim';
import { useTranslatedNavigate } from '../../../utils/routes';
import { CustomerFlightModel } from '../../../models/CustomerFlightModel';
import { ValidationFlightModel } from '../../../models/ValidationFlightModel';

// Utils
import getClaimFlightsSchedule from '../../../services/resources/claimGetters/filterFlightRoute';
import getTranslatedClaimFlightStatus from '../../../services/resources/claimGetters/filterFlightStatus';

/**
 * Component show details for one claim
 */
const ClaimDetails = (props: any): JSX.Element => {
  const { claimNumber } = props;

  const claimId = getIdFromNumber(claimNumber);
  const { formatMessage } = useIntl();
  const translatedNavigate = useTranslatedNavigate();

  // Get it from global store if not hard reloaded
  const claim = useBuild('claims', claimId);

  const [flightsData] = useState(getClaimFlightsSchedule(claim));

  function handleOnUploadClicked() {
    translatedNavigate([
      '/',
      routes.account,
      routes.claim,
      claimNumber,
      routes.claimDocuments,
    ]);
  }

  const customerFlight = flightsData?.[0] as CustomerFlightModel;
  const validationFlight = flightsData?.[0] as ValidationFlightModel;

  const flightDate =
    validationFlight?.flight?.scheduled_gate_departure_date ??
    customerFlight?.flight_date;

  const flightNumber =
    `${validationFlight?.flight?.airline?.name} ${validationFlight?.flight?.flight_number}` ??
    `${customerFlight?.airline?.name} ${customerFlight?.flight_code}`;

  return (
    <AccountContainer
      menuItems={claimDetailsSideMenuItems(claim?.claim_number)}
    >
      {!claim?.meets_requirements ? (
        <Alert level="warning" className={'mb-30'}>
          <div
            className={
              'flex w-full flex-col sm:flex-row sm:items-center items-start justify-between'
            }
          >
            <p className={'m-0 flex-1'}>
              {formatMessage(claimDetailsMessages.alertMessage, {
                // eslint-disable-next-line react/no-multi-comp
                cta: (...chunks) => (
                  <span className={'font-bold'}>{chunks}</span>
                ),
              })}
            </p>
            <button
              className={
                'text-center text-white rounded-full outline-none focus:outline-none bg-orange-300 justify-self-end ml-0 mt-12 sm:mt-0 p-12 h-30 w-96 items-center inline-flex justify-center font-pt-sans font-bold'
              }
              type="button"
              onClick={handleOnUploadClicked}
            >
              {formatMessage(claimDetailsMessages.uploadNowButton)}
            </button>
          </div>
        </Alert>
      ) : null}

      <Card
        style={{
          paddingTop: 20,
          paddingBottom: 30,
          paddingLeft: 30,
          paddingRight: 30,
        }}
      >
        <p className={'font-pt-sans font-bold text-lg text-gray-250 m-0 p-0'}>
          {formatMessage(claimDetailsMessages.claimStatusTitle)}
        </p>
        <hr className={'block w-full border-gray-200 mt-15'} />
        <h3
          className={'text-xll mt-20'}
          dangerouslySetInnerHTML={{
            __html: claim?.currentState?.title,
          }}
        />
        <p
          dangerouslySetInnerHTML={{
            __html: claim?.currentState?.description,
          }}
        />
      </Card>
      <div className={'flex w-full flex-col sm:flex-row'}>
        <Card
          className={'flex-1 mt-30 sm:mr-30 font-open-sans min-h-full'}
          style={{
            paddingTop: 20,
            paddingLeft: 30,
            paddingRight: 30,
            paddingBottom: 30,
          }}
        >
          <p
            className={'font-pt-sans font-bold text-lg text-gray-250 m-0 pb-15'}
          >
            {formatMessage(claimDetailsMessages.flightItinerary)}
          </p>
          <hr className={'border-gray-200 mb-30'} />
          <FlightAirportInformationStripe
            style={{
              fontSize: 15,
              marginBottom: 1,
            }}
            flights={flightsData}
            borderBottom={false}
            className={'font-open-sans'}
          />
          {flightDate ? (
            <>
              <p className="text-base mt-0">
                {formatMessage(getTranslatedClaimFlightStatus(claim)) +
                  ' / ' +
                  format(new Date(flightDate), 'dd/MM/yyyy')}
              </p>
              <p className={'m-0 p-0'}>{flightNumber}</p>
            </>
          ) : null}
        </Card>
        <Card
          className={'flex-1 mt-30 min-h-full'}
          style={{
            paddingTop: 20,
            paddingLeft: 30,
            paddingRight: 30,
            paddingBottom: 30,
          }}
        >
          <PassengerSummary claim={claim} passengers={claim.passengers} />
        </Card>
      </div>
    </AccountContainer>
  );
};
export default ClaimDetails;
