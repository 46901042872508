/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
// Formik
import { Form, Formik, FieldArray } from 'formik';

import { useIntl } from 'react-intl';
import messages from './messages';
import { updatePassenger } from '../../../services/resources/passengers';
import ticketNumberImage from '../../../assets/icons/ticket-number.svg';
import userMinorIcon from '../../../assets/icons/user-minor-icon.svg';
import '../../../utils/yupIntlSetup';
import * as yup from 'yup';
// Components
import Container from '../../../components/Container';
import Main from '../../../components/Main';
import Card from '../../../components/Card';
import StepTitle from '../../../components/StepTitle';
import Input from '../../../components/fields/Input';
import Button from '../../../components/Button';
import userIcon from '../../../assets/icons/user-icon.svg';
import Icon from '../../../components/Icon';
import Alert from '../../../components/Alert';

const validationSchema = yup.object().shape({
  passengers: yup.array().of(
    yup.lazy(() => {
      return yup.object().shape({
        ticket_number: yup.string().when([], (schema, values) => {
          if (values?.value && values?.value.includes('-')) {
            return yup
              .string()
              .matches(/^[0-9.-]*$/, {
                message: messages.validationMsg,
              })
              .max(14)
              .min(14)
              .required();
          } else {
            return yup
              .string()
              .matches(/^[0-9.-]*$/, {
                message: messages.validationMsg,
              })
              .max(13)
              .min(13)
              .required();
          }
        }),
      });
    })
  ),
});

// eslint-disable-next-line react/prop-types
const TicketNumber = ({ claim }) => {
  const { formatMessage } = useIntl();
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('');

  const handleSubmit = (values, { setSubmitting }) => {
    const passengers = claim?.passengers;
    const promises = [];

    values?.passengers.forEach((passenger, index) => {
      promises.push(
        updatePassenger({
          passenger: passengers[index],
          values: {
            ...values.passengers,
            ticket_number: passenger.ticket_number,
          },
        })
      );
    });

    Promise.all(promises)
      .then(() => {
        setAlertType('success');
        checkButtonDisable();
        setSubmitting(false);
        setShowAlert(true);
      })
      .catch(() => {
        setAlertType('danger');
        setSubmitting(false);
        setShowAlert(true);
      });
  };

  const checkTicketNumber = index => {
    return (
      !!claim?.passengers[index].ticket_number &&
      claim?.passengers[index]?.approved
    );
  };

  const checkButtonDisable = () => {
    return claim?.passengers.every(
      passenger => passenger?.ticket_number && passenger?.approved
    );
  };

  const initialValues = {
    passengers: claim?.passengers,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={true}
      // eslint-disable-next-line react/jsx-no-bind
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, isValid, values }) => (
        <Container>
          <Main>
            <Form>
              <Card
                onlyDesktop={true}
                className="mb-50"
                style={{ paddingBottom: 0 }}
              >
                <div className="flex justify-center sm:justify-between items-center text-center">
                  <StepTitle>{formatMessage(messages.header)}</StepTitle>
                </div>
                <Card onlyMobile={true}>
                  <div className="mt-0 sm:-mt-10 mb-5 sm:flex items-start">
                    <p className="mt-0 mb-20 sm:mr-20">
                      {formatMessage(messages.explanation)}
                    </p>
                    <img
                      className="pnr-img w-full sm:w-auto sm:-mt-20 mb-20"
                      src={ticketNumberImage}
                      alt={formatMessage(messages.imageAlternative)}
                      aria-hidden="true"
                    />
                  </div>
                  <FieldArray
                    name="passengers"
                    render={() =>
                      values?.passengers?.map((item, index) => (
                        <>
                          <div className="flex items-center">
                            <Icon
                              style={{ width: '5%' }}
                              src={
                                item?.person?.is_minor
                                  ? userMinorIcon
                                  : userIcon
                              }
                              alt={formatMessage(messages.imageAlternative)}
                              aria-hidden="true"
                            />
                            <h4 className="mr-5">
                              {item?.person?.first_name}{' '}
                              {item?.person?.last_name}
                            </h4>
                            {item?.main_booker && <span>({formatMessage(messages.mainBookerLabel)})</span>}
                            {item?.person?.is_minor && <span>({formatMessage(messages.minorLabel)})</span>}
                          </div>
                          <Input
                            key={item.id + index}
                            name={`passengers[${index}].ticket_number`}
                            label={formatMessage(messages.ticketNmrLabel)}
                            placeholder={formatMessage(
                              messages.ticketNmrPlaceholder
                            )}
                            marginLeft="36"
                            className="mb-10"
                            disabled={checkTicketNumber(index)}
                          />
                        </>
                      ))
                    }
                  />
                  {showAlert && (
                    <Alert
                      level={alertType}
                      className="w-full my-20"
                      setCloseIcon={setShowAlert}
                      iconClassName="cursor-pointer absolute right-80"
                    >
                      {alertType === 'success'
                        ? formatMessage(messages.successMessage)
                        : formatMessage(messages.successMessage)}
                    </Alert>
                  )}
                  <div
                    className={
                      'text-center mx-15 sm:mx-0 my-25 sm:my-40 mb-30 flex sm:inline-flex flex-col-reverse sm:flex-row w-auto sm:w-auto'
                    }
                  >
                    <Button
                      theme="primary"
                      type="submit"
                      showIcon={false}
                      disabled={
                        isSubmitting || checkButtonDisable() || !isValid
                      }
                      isSubmitting={isSubmitting}
                    >
                      {formatMessage(messages.updateButtonText)}
                    </Button>
                  </div>
                </Card>
              </Card>
            </Form>
          </Main>
        </Container>
      )}
    </Formik>
  );
};

export default TicketNumber;
