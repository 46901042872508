// React specific
import React from 'react';

// Components
import ClaimListItem from './ClaimListItem';

/**
 * ClaimList for rendering single claims
 * @param props
 * @returns
 */

const ClaimList = (props: any): JSX.Element => {
  const claims = props.claims;

  return (
    <ul>
      {claims.length > 0 ? (
        claims.map((claim: any) => (
          <ClaimListItem key={claim.id} claimData={claim} />
        ))
      ) : (
        // Show empty list
        <div />
      )}
    </ul>
  );
};

export default ClaimList;
