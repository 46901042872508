/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { useIntl } from 'react-intl';
import messages from '../newMessages';
import translations from '../../../../translations/main.json';
import { useTranslatedNavigate } from '../../../../utils/routes';

const DIVIDER_STYLE = {
  margin: '2% 0',
  borderBottom: '1px solid #d9dbe9',
};

const SearchedQuestions = (props: {
  filteredQuestions: any[];
  hasSetSelectedValue: any;
  allQuestions: any[];
}) => {
  const { filteredQuestions, hasSetSelectedValue, allQuestions } = props;
  const { formatMessage } = useIntl();
  const translatedNavigate = useTranslatedNavigate();

  const handleSelectAnswer = (item: any) => {
    const question = allQuestions.filter(quest =>
      quest?.question?.toLowerCase()?.includes(item?.question?.toLowerCase())
    );
    hasSetSelectedValue(true);
    translatedNavigate(
      '/' +
        translations['cc4.seo.route.account'] +
        '/' +
        translations['cc4.seo.route.help-center'] +
        `/${question[0]?.id}`,
      {
        state: {
          question,
          searchedText: item?.question,
        },
      }
    );
  };

  return (
    <>
      {filteredQuestions?.length ? (
        filteredQuestions?.map(item => (
          <>
            <div key={item?.id} style={{ margin: '1% 0' }}>
              <h4
                onClick={() => handleSelectAnswer(item)}
                style={{
                  textDecoration: 'underline',
                  fontWeight: 500,
                  fontSize: '18px',
                  lineHeight: '28px',
                  cursor: 'pointer',
                }}
              >
                {item?.question}
              </h4>
            </div>
            <div style={DIVIDER_STYLE} />
          </>
        ))
      ) : (
        <span style={{ fontWeight: 500, fontSize: '18px', lineHeight: '28px' }}>
          {formatMessage(messages.noAnswersFound)}
        </span>
      )}
    </>
  );
};

export default SearchedQuestions;
