import { useField } from 'formik';
import React from 'react';

interface CharCounterProps {
  observedField: string;
  allowedChars: number;
  className: string;
}

const CharCounter = ({
  observedField,
  allowedChars,
  className,
}: CharCounterProps): JSX.Element => {
  const [field] = useField(observedField);

  return (
    <div className={className}>
      <p className="m-0 p-0 text-right">
        {field.value?.length
          ? allowedChars - field.value?.length
          : allowedChars}{' '}
        / {allowedChars}
      </p>
    </div>
  );
};

export default CharCounter;
