import React from 'react';
import clsx from 'clsx';

export const Listing = ({
  children,
  className,
  ...props
}: {
  children: React.ReactNode;
  className?: string;
}): JSX.Element => {
  return (
    <div
      className={clsx(
        'shadow-sm rounded border-gray-50 bg-white listing mb-40 px-10 sm:px-0',
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};

// eslint-disable-next-line
export const ListingRow = ({
  children,
  className,
  ...props
}: {
  children: React.ReactNode;
  className?: string;
}): JSX.Element => {
  return (
    <div
      className={clsx(
        'flex w-full flex-col relative sm:flex-row sm:items-center md:flex-row sm:h-50 border-b border-gray-50 px-10 sm:px-30 py-20 sm:py-0',
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};
