// React specific
import clsx from 'clsx';
import React from 'react';
import { format } from 'date-fns';

export enum MessageSender {
  CUSTOMER = 'CUSTOMER',
  YOURCE = 'YOURCE',
}

interface MessageBubbleProps {
  authorName: string;
  sentAt: string;
  sender: MessageSender;
  messageTag: string;
  messageText: string;
}

const MessageBubble = (props: MessageBubbleProps): JSX.Element => {
  const { authorName, sentAt, sender: order, messageTag, messageText } = props;

  return (
    <div
      className={clsx('flex flex-col break-words whitespace-pre-wrap pb-30', {
        ['self-end lg:pl-150 lg:pr-50 pl-0 pr-0 items-end']:
          order === MessageSender.CUSTOMER,
        ['self-start lg:pl-50 lg:pr-150 pl-0 pr-0 items-start']:
          order === MessageSender.YOURCE,
      })}
    >
      <div className={'flex flex-row m-10 items-center'}>
        <p className={'m-0 pr-8 font-pt-sans font-bold'}>{authorName}</p>
        <p className={'m-0 pr-20 text-sm'}>
          {format(new Date(sentAt), 'dd/MM/yyyy')}
        </p>
        <p className={'m-0 px-8 text-xs border border-gray-200 rounded-full'}>
          {messageTag}
        </p>
      </div>
      <div
        className={clsx('flex rounded-xl shadow-sm max-w-680', {
          ['bg-green-100']: order === MessageSender.CUSTOMER,
          ['bg-brown-300']: order === MessageSender.YOURCE,
        })}
      >
        <p
          className={'text-black m-0 p-20 leading-7 max-w-680'}
          dangerouslySetInnerHTML={{
            __html: messageText,
          }}
        />
      </div>
    </div>
  );
};

export default MessageBubble;
