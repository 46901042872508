import React from 'react';
import { useIntl } from 'react-intl';

import Loading from '../../../../components/Loading';
import StepTitle from '../../../../components/StepTitle';
import DocsInfoGroup, { DocsInfoGroupProps } from './DocsInfoGroup';

import { useJsonApi } from '../../../../services/GlobalStore/GlobalStore';

import messages from './messages';
import OtherDocumentsGroup from './OtherDocumentsGroup';
import PassengerShareUrls from './PassengerShareUrls';

const ClaimRequirements = ({ claimId }: { claimId: string }) => {
  const { formatMessage } = useIntl();

  const { data, loading } = useJsonApi(
    `claims/${claimId}/requirements`,
    {
      queryParams: {
        include: [
          'targetCategory',
          'docsInfoPassengerStatuses.passenger.person.shareablePassengerPageUrl',
        ].join(),
        filter: {
          'docsInfoPassengerStatuses.status': {
            in: ['pending', 'failed'].join(),
          },
        },
      },
      credentials: 'include',
      cachePolicy: 'no-cache',
    },
    []
  );

  if (loading) return <Loading />;

  if (!data?.length) return <OtherDocumentsGroup />;

  return (
    <>
      {data?.length ? (
        <PassengerShareUrls data={data} claimId={claimId} />
      ) : null}
      <StepTitle className="mb-30">
        {formatMessage(messages.requirementsMainTitle)}
      </StepTitle>
      {data?.map?.((docsInfoGroup: DocsInfoGroupProps) => (
        <DocsInfoGroup {...docsInfoGroup} key={docsInfoGroup.id} />
      ))}
      <OtherDocumentsGroup />
    </>
  );
};

export default ClaimRequirements;
