import React from 'react';
import HelpSearchBar from './HelpSearchBar';
import HelpTopHeader from './HelpTopHeader';
import { isMobile } from 'react-device-detect';

const HelpTop = ({
  faqCategories,
  searchedText,
}: {
  faqCategories: any[];
  searchedText: string;
}) => {
  return (
    <div
      className="flex flex-col"
      style={
        searchedText?.length && !isMobile
          ? { alignItems: 'baseline', maxWidth: '625px' }
          : { alignItems: 'center' }
      }
    >
      <HelpTopHeader searchedText={searchedText} />
      <HelpSearchBar faqCategories={faqCategories} />
    </div>
  );
};

export default HelpTop;
