import React from 'react';
import { isMobile } from 'react-device-detect';
import { useIntl } from 'react-intl';
// Components
import StepTitle from '../../../components/StepTitle';
import questionmarkIcon from '../../../assets/images/account-page/questionmarkIcon.png';
import messages from './newMessages';
import translations from '../../../translations/main.json';
import { useTranslatedNavigate } from '../../../utils/routes';

const CommonQuestions = (props: { questions: any[] }) => {
  const { questions } = props;
  const translatedNavigate = useTranslatedNavigate();

  const truncateAnswer = (answer: string) => {
    return answer.substring(0, 120) + '...';
  };

  const { formatMessage } = useIntl();

  const handleSelectQuestion = (question: any) => {
    translatedNavigate(
      translations['cc4.seo.route.help-center'] + '/' + question?.question,
      {
        state: {
          question,
          searchedText: question?.question,
        },
      }
    );
  };

  return (
    <>
      <div className="flex justify-center">
        <StepTitle
          style={{
            marginBottom: 20,
            marginTop: '60px',
            // textAlign: 'center'
          }}
          className="text-black lg:text-xxl text-xll text-center"
        >
          <img
            src={questionmarkIcon}
            alt=""
            style={{ float: 'left', marginRight: '10px', marginTop: '-5px' }}
          />{' '}
          {formatMessage(messages.commonQuestionsTitle)}
        </StepTitle>
      </div>
      {questions?.length ? (
        <div
          className="questions-container flex flex-wrap"
          style={{ gap: '5%' }}
        >
          {questions[1]?.faqs?.slice(0, 4).map(item => (
            <div
              className="flex-[1_1_45%]"
              key={item?.id}
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => handleSelectQuestion(item)}
              style={
                isMobile
                  ? { marginTop: '20px', cursor: 'pointer' }
                  : { flex: '1 1 45%', marginTop: '20px', cursor: 'pointer' }
              }
            >
              <span
                style={{
                  fontWeight: 500,
                  fontSize: '18px',
                  lineHeight: '28px',
                  color: '#000000',
                  textDecoration: 'underline',
                }}
              >
                {item.question}
              </span>
              <br />
              <span
                style={{
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '30px',
                  color: '#6f6c72',
                }}
              >
                {truncateAnswer(item.answer)}
              </span>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};

export default CommonQuestions;
