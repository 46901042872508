import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

// Components
import InputGroupWrapper from './InputGroup';
import CharCounter from '../CharCounter';

// Utils
import translatable from '../../utils/propTypes/translatable';
import { useFormikContext } from 'formik';

const TextAreaField = ({
  name,
  className,
  placeholder,
  rows,
  cols,
  id,
  allowedChars,
  ...props
}) => {
  const intl = useIntl();
  const formikContext = useFormikContext();
  const { setFieldValue } = formikContext;

  function handleOnChange(e) {
    if (
      !allowedChars ||
      (allowedChars > 0 && e.target.value.length <= allowedChars)
    ) {
      setFieldValue(name, e.target.value);
    }
  }

  return (
    <>
      <textarea
        id={id}
        data-hj-allow
        name={name}
        rows={rows}
        cols={cols}
        placeholder={
          placeholder?.id ? intl.formatMessage(placeholder) : placeholder
        }
        className={clsx(
          'form-input',
          'w-full',
          'h-full',
          'sm:w-full',
          'sm:h-full',
          'placeholder-gray-300',
          {
            'form-input-error': props.hasError,
          },
          className
        )}
        aria-invalid={props.hasError}
        {...props.field}
        onChange={handleOnChange}
      />
      {allowedChars > 0 ? (
        <CharCounter
          observedField={name}
          allowedChars={allowedChars}
          className="flex-1"
        />
      ) : null}
    </>
  );
};

TextAreaField.defaultProps = {
  rows: '4',
  cols: '50',
};

TextAreaField.propTypes = {
  allowedChars: PropTypes.number,
  className: PropTypes.string,
  cols: PropTypes.string,
  countChar: PropTypes.bool,
  field: PropTypes.object,
  form: PropTypes.object,
  hasError: PropTypes.bool,
  id: PropTypes.string,
  isTouched: PropTypes.bool,
  label: translatable,
  name: PropTypes.string,
  placeholder: translatable,
  rows: PropTypes.string,
};

// eslint-disable-next-line
const TextArea = props => (
  <InputGroupWrapper {...props} component={TextAreaField} />
);

TextArea.propTypes = {
  label: translatable,
  name: PropTypes.string.isRequired,
  placeholder: translatable,
};

export default TextArea;
