import React from 'react';
import { useIntl } from 'react-intl';

import Button from '../../../../../components/Button';
import { ListingRow } from '../../../../../components/Listing';
import Chip from '../../../../../components/Chip';
import TruncatedText from '../../../../../components/TruncatedText';
import Icon from '../../../../../components/Icon';
import imageIconGreen from '../../../../../assets/icons/image-icon-green.svg';

import { useModal } from '../../../../../components/Modals/ModalContext';
import { useHandleError } from '../../../../../services/api';
import { deleteDossierItem } from '../../../../../services/resources/dossierItems';
import BinIcon from '../../../../../assets/icons/bin-icon.inline.svg';
import messages from './../messages';
import uploadDocumentMessages from '../../../../../views/UploadDocuments/messages';

import { PassengerModel } from '../../../../../models/PassengerModel';
import { DossierItemModel } from '../../../../../models/DossierItemModel';

const UploadedDocumentItem = ({
  dossierItem,
  claimApproved,
  reloadDocuments,
}: {
  dossierItem: DossierItemModel;
  claimApproved: boolean;
  reloadDocuments: () => void;
}) => {
  const { formatMessage } = useIntl();

  const [handleError] = useHandleError();

  const [showModal] = useModal('default');

  const isMobile = window.innerWidth < 617;

  function handleRemoveDocument() {
    showModal({
      title: formatMessage(uploadDocumentMessages.modalMessage),
      onCancelText: formatMessage(uploadDocumentMessages.modalOnCancel),
      onConfirmText: formatMessage(uploadDocumentMessages.modalOnConfirm),
      onConfirm: async () => {
        try {
          await deleteDossierItem({ dossierItemId: dossierItem.id });
          reloadDocuments();
        } catch (error) {
          handleError(error);
        }
      },
    });
  }

  return (
    <ListingRow key={`key-${dossierItem?.id}`} data-testid="uploadedFile">
      <div
        className="sm:text-md"
        style={{
          width: isMobile ? undefined : '35%',
        }}
      >
        <TruncatedText maxLength={isMobile ? 60 : 40}>
          {dossierItem.passengers?.length
            ? dossierItem.passengers
                ?.map?.(
                  ({ person }: PassengerModel) =>
                    `${person?.first_name} ${person?.last_name}`
                )
                .join(', ')
            : null}
        </TruncatedText>
      </div>

      {/* Type column */}
      <div
        className="text-md text-gray-300 sm:text-xs"
        style={{
          width: isMobile ? undefined : '20%',
        }}
      >
        <TruncatedText maxLength={22}>
          {dossierItem?.category?.name}
        </TruncatedText>
      </div>

      {/* Status column */}
      <div
        className="text-right sm:text-justify absolute top-10 right-0 sm:static pr-10 sm:pr-0"
        style={{
          width: isMobile ? undefined : '15%',
        }}
      >
        <Chip color={'success'}>
          {formatMessage(
            dossierItem.category?.slug?.includes('powers_of_attorney')
              ? messages.documentSignedStatus
              : messages.fileUploadedStatus
          )}
        </Chip>
      </div>
      {/* File name column */}
      <div
        className="hidden sm:block text-xs"
        style={{
          width: '20%',
        }}
      >
        {dossierItem.category?.slug?.includes('powers_of_attorney') ? null : (
          <TruncatedText>{dossierItem?.file?.filename}</TruncatedText>
        )}
      </div>

      {/* Trash button */}
      {isMobile ? null : (
        <div
          style={{
            width: '10%',
          }}
          className="flex justify-end"
        >
          <div className="group flex">
            <Icon
              src={imageIconGreen}
              className="ml-5"
              alt="file preview"
              title={dossierItem.file.filename}
              onClick={null}
            />
            <span className="z-10 pointer-events-none absolute w-max rounded bg-white px-2 mt-30 text-sm font-medium text-gray-50 opacity-0 shadow transition-opacity group-hover:opacity-100">
              <img src={dossierItem?.file?.preview_link} />
            </span>
            {claimApproved ? null : (
              <Button
                size="tiny"
                onClick={handleRemoveDocument}
                theme="link"
                className="ml-15 mt-25 sm:mt-0 sm:p-0 w-full sm:w-auto min-h-36 h-auto text-center sm:border-none sm:text-sm sm:basis-[5%]"
              >
                <BinIcon className="fill-black" />
              </Button>
            )}
          </div>
        </div>
      )}
    </ListingRow>
  );
};

export default UploadedDocumentItem;
